import React, { Component, ErrorInfo } from 'react';
import PopError from './PopError';
import { error } from 'console';
import { ErrorBoundary } from 'react-error-boundary';

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

const ReactErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  return <ErrorBoundary FallbackComponent={PopError}>{children}</ErrorBoundary>;
};

export default ReactErrorBoundary;
