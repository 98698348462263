import { AcademicCapIcon, UserGroupIcon, ArrowPathIcon, CheckCircleIcon } from '@heroicons/react/20/solid';

const stats = [
  {
    name: 'Sourcefeeds',
    value: '2031',
    change: '+4.75%',
    changeType: 'positive',
    icon: AcademicCapIcon,
  },
  {
    name: 'Contacts',
    value: '787',
    change: '+12.50%',
    changeType: 'negative',
    icon: UserGroupIcon,
  },
  {
    name: 'Last Synced',
    value: '49',
    change: '-1.39%',
    icon: ArrowPathIcon,
  },
  {
    name: 'Ready to Sync',
    value: '23',
    change: '+10.00%',
    icon: CheckCircleIcon,
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function StatCard() {
  return (
    <dl className="mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
      {stats.map((stat) => {
        const Icon = stat.icon;
        return (
          <div
            key={stat.name}
            className="flex flex-row items-start bg-shell gap-4 px-2 py-4 sm:px-4 xl:px-8 rounded-md shadow-md"
          >
            <div className="w-20 h-20 bg-foreground rounded-md flex items-center justify-center">
              <Icon
                className="w-12 h-12 text-background"
                aria-hidden="true"
              />
            </div>
            <div className="flex-1 flex flex-col justify-between pl-2">
              <div className="flex flex-wrap items-center justify-between">
                <dt className="text-md font-medium leading-6 text-foreground space-x-2">{stat.name}</dt>
                <div
                  className={classNames(
                    stat.changeType === 'negative' ? 'text-rose-600' : 'text-green-500',
                    'pt-1 text-md font-medium ml-2',
                  )}
                >
                  {stat.change}
                </div>
              </div>
              <dd className="w-full flex-none text-4xl font-bold leading-10 tracking-tight text-foreground items-end pt-2.5">
                {stat.value}
              </dd>
            </div>
          </div>
        );
      })}
    </dl>
  );
}
