import React, { FC, ButtonHTMLAttributes } from 'react';

interface PrimaryButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
}

const PrimaryButton: FC<PrimaryButtonProps> = ({ text, className, ...props }) => (
  <button
    className={`rounded-md px-3 py-2 text-xs md:text-sm sm:text-xs bg-primary text-slate-50 font-semibold shadow-sm hover:bg-primary/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 whitespace-nowrap ${className}`}
    {...props}
  >
    {text}
  </button>
);

export default PrimaryButton;
