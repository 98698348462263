import React, { FC } from 'react';
import { Link } from 'react-router-dom';

interface HomeProps {}

const Home: FC<HomeProps> = () => (
  <div className="bg-background flex justify-center items-center h-screen">
    <div>
      <Link to="/styling">
        <button
          type="button"
          className="mr-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Styling
        </button>
      </Link>

      <Link to="/app-shell">
        <button
          type="button"
          className="mr-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          AppShell
        </button>
      </Link>
      <Link to="/base-list-view-source-feeds">
        <button
          type="button"
          className="mr-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          BaseListView - SourceFeed
        </button>
      </Link>
      <Link to="/base-list-view-users">
        <button
          type="button"
          className="mr-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          BaseListView - Users
        </button>
      </Link>
      <Link to="/create-new-source-feed">
        <button
          type="button"
          className="mr-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          CreateNewSourceFeedDialogue
        </button>
      </Link>
      <Link to="/toggle-button">
        <button
          type="button"
          className="mr-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          ToggleButton
        </button>
      </Link>

      <Link to="/stat-card">
        <button
          type="button"
          className="mr-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          StatCard
        </button>
      </Link>

      <Link to="/search-bar">
        <button
          type="button"
          className="mr-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          SearchBar
        </button>
      </Link>

      <Link to="/settings">
        <button
          type="button"
          className="mr-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Settings
        </button>
      </Link>

      <Link to="/login">
        <button
          type="button"
          className="mr-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Login
        </button>
      </Link>

      <Link to="/registration">
        <button
          type="button"
          className="mr-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Registration
        </button>
      </Link>

      <Link to="/loading-spinner">
        <button
          type="button"
          className="mr-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          LoadingSpinner
        </button>
      </Link>

      <Link to="/particles">
        <button
          type="button"
          className="mr-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Particles
        </button>
      </Link>

      <Link to="/legacy">
        <button
          type="button"
          className="rounded-md bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Legacy
        </button>
      </Link>
    </div>
  </div>
);

export default Home;
