import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import React, { useEffect, useState } from "react";
import Config from '../../AppConfig.json';
import User from "../../Models/User";
import UserProfileBottom from "./UserProfile.Bottom";
import UserProfileMiddle from "./UserProfile.Middle";
import "./UserProfileDrawerDetails.styles.css";
import UserProfileTop from "./UserProfile.Top";
/* icons */
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import Backdrop from "@mui/material/Backdrop";
import UserRolesListEditableView from "../List/UserRolesListEditableView";
import { CircularProgress } from '@mui/material';
import Organization from '../../Models/Organization';

type userDetailProps = {
    closeWindow?: () => void;
    userID?: string;
}

const UserProfileDrawerDetails = ({ closeWindow, userID }: userDetailProps) => {
    const [user, setUser] = useState<User>();
    const [open, setOpen] = React.useState(true);
    const [refreshData, setRefreshData] = useState(false);
    const [editRolesToggle, setEditRolesToggle] = useState(false);
    const [orgName, setOrgName] = useState("");

    useEffect(() => {
        fetch(Config.apiRootURL + "/organization/1/user/" + userID)
            .then(response => response.json())
            .then(json => {
                const userData = new User(json);
                fetch(Config.apiRootURL + "/organization/" + userData.organizationID + "/user/" + userData.id + "/role")
                    .then(response => response.json())
                    .then(json => {
                        userData.roles = json;
                    }).then(() => fetch(Config.apiRootURL + "/organization/" + userData.organizationID + "?includeUsers=false")
                        .then(response => response.json())
                        .then(json => {
                            setOrgName(json.organizationName);
                            setUser(userData);
                            setOpen(false);
                        }))
            })
    }, [refreshData]);

    function setRefreshDataFunc(refreshData: boolean) {
        setRefreshData(refreshData);
    }

    function handleEditRolesToggle() {
        if (!editRolesToggle)
            setEditRolesToggle(true)
        else setEditRolesToggle(false)
    }

    if (open && !user) return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>);
    else if (user)
        return (

            <div style={{ position: "relative", height: "100%" }}>
                <div>
                    <span style={{ display: "flex", justifyContent: "right" }}>
                        <IconButton onClick={() => setRefreshDataFunc(!refreshData)}>
                            <RefreshIcon />
                        </IconButton>
                        <IconButton onClick={closeWindow}>
                            <CloseIcon />
                        </IconButton>
                    </span>
                </div>

                <UserProfileTop handleEditRolesToggle={handleEditRolesToggle} user={user} />
                {editRolesToggle && (<AppBar style={{ fontSize: 20, boxShadow: "0px 0px 0px 0px", paddingLeft: 30, height: 25 }} position='static'>Edit Roles</AppBar>)}
                <div style={{ marginTop: 20, marginLeft: 30, marginRight: 30 }}>
                    {!editRolesToggle && (
                        <div>
                            <UserProfileMiddle user={user} orgName={orgName} />
                            <UserProfileBottom user={user} />
                        </div>
                    )}
                    {editRolesToggle && (
                        <UserRolesListEditableView user={user} />
                    )}
                </div>


            </div>
        )
    else return (<></>)
}

{/* <AppBar position="absolute" color="inherit" sx={{ top: 'auto', bottom: 0 }}>
<Toolbar style={{ display: "flex", justifyContent: "right" }}>
    <span>
        <Button onClick={closeWindow} variant="text">Cancel</Button>
        <Button variant="text">Save Changes</Button>
    </span>
</Toolbar>
</AppBar> */}

export default UserProfileDrawerDetails;