import { Switch } from '@headlessui/react';
import { MoonIcon, SunIcon } from '@heroicons/react/20/solid'; // import the icons
import useDarkModeStore from '../../../stores/useDarkModeStore';

export default function ToggleButton() {
  // Define a type for the Zustand store
  type UseStoreType = {
    darkMode: boolean;
    setDarkMode: (darkMode: boolean) => void;
  };

  // Use the Zustand store and cast it to the UseStoreType
  const { darkMode, setDarkMode } = useDarkModeStore() as UseStoreType;

  return (
    <Switch
      checked={darkMode}
      onChange={setDarkMode}
      className={
        'bg-tertiary relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out'
      }
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={
          (darkMode ? 'translate-x-5' : 'translate-x-0') +
          ' pointer-events-none h-5 w-5 transform rounded-full bg-background shadow ring-0 transition duration-200 ease-in-out items-center flex justify-center'
        }
      >
        {darkMode ? <MoonIcon className="h-4 w-4 text-foreground" /> : <SunIcon className="text-foreground" />}
      </span>
    </Switch>
  );
}
