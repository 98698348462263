import React, { useState, ReactNode, useEffect } from "react";
import ResponsiveAppBar from "./ResponsiveAppBar";
import Sidebar from "./Sidebar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
  },
  palette: {
    primary: {
      main: "#EFEFEF",
    },
  },
});

interface AppShellProps {
  children: ReactNode;
}

function AppShell({ children }: AppShellProps) {
  const [sidebarWidth, setSidebarWidth] = useState(theme.spacing(7)); // default width for closed sidebar

  const handleCollapse = () => {
    setSidebarWidth(theme.spacing(7)); // Set width for closed sidebar
  };

  const handleExpand = () => {
    setSidebarWidth(theme.spacing(20)); // Set width for expanded sidebar
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <CssBaseline />
        <ResponsiveAppBar />
        <Sidebar
          open={false}
          onCollapse={handleCollapse}
          onExpand={handleExpand}
        />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            marginLeft: sidebarWidth, // Use the state value here
            transition: theme.transitions.create("margin", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen, // Make sure this duration matches your Sidebar's
            }),
          }}
        >
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default AppShell;
