class Organization {
    id: string
    organizationName: string
    ownerID: string
    licensed: boolean
    created: Date
    enabled: boolean

    public constructor(json: any) {
        this.id = json.id;
        this.organizationName = json.organizationName;
        this.ownerID = json.ownerID;
        this.licensed = json.licensed;
        this.created = json.created;
        this.enabled = json.enabled;

    };


}
export default Organization;