import React, { useState, useEffect } from 'react';
//import UserProfile from '../UserProfileDetails/UserProfile';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridEventListener, GridRowParams, GridValueGetterParams, MuiEvent } from '@mui/x-data-grid';
import Drawer from '@mui/material/Drawer';
import SourceFeed from "../../Models/SourceFeed";
import { SettingsBrightnessSharp } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import { ClickAwayListener, CssBaseline } from '@mui/material';
import internal from 'stream';
import Config from '../../AppConfig.json';
import SourceFeedDetails from '../SourceFeedDetails/SourceFeedDetails';

//https://mui.com/material-ui/react-table/#sorting-amp-selecting

interface Data {
  id: string,
  name: string,
  sourceDomain: string,
  locationURL: string,
  lastSyncTime: string,
  enabled: boolean,
}

function createData(
  id: string,
  name: string,
  sourceDomain: string,
  locationURL: string,
  lastSyncTime: string,
  enabled: boolean,
): Data {
  return {
    id,
    name,
    sourceDomain,
    locationURL,
    lastSyncTime,     
    enabled,
  };
}


const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90, hide: true},
  {
    field: 'name',
    headerName: 'Name',
    width: 300,
    
  },
  {
    field: 'sourceDomain',
    headerName: 'Source Domain',
    width: 300,
   
  },
  {
    field: 'locationURL',
    headerName: 'Location URL',
    type: 'string',
    width: 600,

  },
  {
    field: 'lastSyncTime',
    headerName: 'Last Synced',
    type: 'string',
    width: 180,

  },  
  {
    field: 'enabled',
    headerName: 'Enabled',
    type: 'boolean',
    width: 100,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      if (params.row.enabled) return (<Chip label="Active" color="success" size="small" variant="outlined" />);
      return <Chip label="Disabled" color="error" size="small" variant="outlined" />;
    }

  }
];

var suppressOnce:boolean = false;

const SourceFeedsListView = () => {
 
  const [drawervisibility, setDrawerVisibility] = useState(false);
  const [selectedSourceFeed, setSelectedSourceFeed] = useState<string>();
  const [rows, setRows] = useState<Data[]>([]);
  const [open, setOpen] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [firstTimePageLoad, setFirstTimePageLoad] = React.useState(true);
  let offset = (page * pageSize); 

  function populateListView(orgId: number = 1, incDisabled: boolean = false, offset: number, limit: number) {

    //TODO: Implement a connection to sourcefeed with organizationID so we can pull all sourcefeeds under OrgID
    fetch(`${Config.apiRootURL}/sourcefeed/?incDisabled=${incDisabled}&offset=${offset}&limit=${limit}`)
      .then(response => response.json())
      .then(json => {
        const sourceFeedsData: SourceFeed[] = json.results;        
        const dataSet = sourceFeedsData.map(p => createData(p.id, p.name, p.sourceDomain, p.locationURL, new Date(p.lastSyncTime).toLocaleString(), p.enabled));        
        setRows(dataSet);
        if (firstTimePageLoad) {
          setTotalRecordCount(json.totalRecordCount);
          setFirstTimePageLoad(false);         
        }       
       setOpen(false);
      });
  };


  function handlePageChange(newPageChange: number) {     
    let totalPages = Math.ceil(totalRecordCount / pageSize);
    
    if (suppressOnce){   
      suppressOnce = false;
      return;
    }
   
    if (newPageChange <= totalPages)
      setPage(newPageChange);
    else return;
  }

  function handlePageSizeChange(newPageSize: number) {
    let totalPages = Math.ceil(totalRecordCount / pageSize);   
    if ((page+1) >= totalPages) suppressOnce = true;
    setPageSize(newPageSize); 
    setPage(0);
  }

  const handleClickAway = () => {   
    setDrawerVisibility(false);
  };

  useEffect(() => {    
    populateListView(10, true, offset, pageSize);   
  }, [pageSize, page]);


  if (open == true) return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>);

  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <DataGrid
      autoHeight 
       sx={{
        '.MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold !important',
          justifyContent: 'left',   
          fontSize: '1rem'      
        },
        '.MuiDataGrid-columnHeader:focus-within': {
          outline: 'none !important'
        },
        '.MuiDataGrid-cell:focus-within': {
          outline: 'none !important'
      },
      }}
        rows={rows}        
        columns={columns}
        paginationMode="server"
        rowCount={totalRecordCount}
        page={page}
        pageSize={pageSize}
        onPageChange={(newPageChange) => handlePageChange(newPageChange)}
        onPageSizeChange={(newPageSize, event) => { handlePageSizeChange(newPageSize); }}
        
        rowsPerPageOptions={[10, 20, 50, 100]}    
        isCellEditable={(param) => false}
        disableSelectionOnClick
        onRowClick={(params, event) => {         
          setSelectedSourceFeed(params.row.id);
          setDrawerVisibility(true);          
        }}
      />
      <Drawer      
        
        anchor="right"
        open={drawervisibility}
        ModalProps={{ onBackdropClick: handleClickAway }}
        PaperProps={{
          sx: { width: 600 },
        }}
      >    
        <SourceFeedDetails closeWindow={() => setDrawerVisibility(false)} sourceFeedID={selectedSourceFeed} />
      </Drawer>      
    </Box>
  );
}

export default SourceFeedsListView;