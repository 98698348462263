import { XMarkIcon, PencilIcon, ArrowPathIcon, DocumentCheckIcon } from '@heroicons/react/24/outline';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import SourceFeed from '../../../models/SourceFeed';
import Config from '../../../app-config.json';
import UserDetailsReadOnlyDrawer from './UserDetailsReadOnlyDrawer';
import { Dialog, Transition } from '@headlessui/react';
import SourceFeedDetailsEditDrawer from '../SourceFeedDetailsDrawer/SourceFeedDetailsEditDrawer';
import UserDetailsEditDrawer from './UserDetailsEditDrawer';
import User from '../../../models/User';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

// Define an empty SourceFeed
let emptyUser: User = new User([]);

// Define Props type for the component
type Props = {
  userId: string;
  closeDrawer: () => void; // Function to close the drawer
  updateParentRow: (rowID: string) => void;
};

// Define the functional component
export default function UserDetailsParentDrawer({ userId, closeDrawer, updateParentRow }: Props) {
  // State variables
  // const [editedUser, setEditedUser] = useState<User>(emptyUser);
  const [refreshData, setRefreshData] = useState(false); // State for refreshing data
  const [edit, setEdit] = useState(false); // State for editing mode
  const [drawerVisible, setDrawerVisible] = useState(false); // State to control drawer visibility
  const [showConfirmWindow, setShowConfirmWindow] = useState(false);
  const [pendingChange, setPendingChange] = useState(false); // State for pending changes
  const drawerRef = useRef<HTMLDivElement>(null); // Reference to the drawer DOM element
  const editExit = useRef(false);
  const pendingChangeRef = useRef(pendingChange);
  const methods = useForm<User>({
    defaultValues: {
      firstName: '', // Set the default value based on the User object's enabled property
    },
  });

  // Effect hook to sync the ref with the state
  useEffect(() => {
    pendingChangeRef.current = pendingChange;
  }, [pendingChange]);

  // Effect hook to handle click outside the drawer to close it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const topBanner = document.getElementById('app-shell-top-banner');

      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target as Node) &&
        document.querySelector('#top-banner') != event.target &&
        !(topBanner && topBanner.contains(target))
      ) {
        if (edit) {
          setShowConfirmWindow(true);
        } else {
          closeDrawer();
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeDrawer, edit]);

  // Effect hook to open the drawer when the id changes
  useEffect(() => {
    setDrawerVisible(true); // Open the drawer when it's supposed to be visible
  }, [userId]);

  // Function to handle click on the refresh button
  function handleOnClickRefresh() {
    methods.reset();
    setPendingChange(false);
    setRefreshData((prevRefreshData) => !prevRefreshData); // Toggle the refresh data state
  }

  // Function to handle pending change
  const handlePendingChanges = () => {
    if (pendingChange != true) {
      setPendingChange(true);
    }
  };

  //When the close button is clicked in edit mode, check for pending changes.
  function handleEditDrawerCloseButtonClick(): void {
    if (pendingChangeRef.current) {
      setShowConfirmWindow(() => {
        editExit.current = true;
        return true;
      });
    } else {
      setEdit(false); // Close the edit page of drawer and go back to readOnly details
    }
  }

  function handleConfirmLeaveDialogClose(): void {
    if (editExit.current) {
      editExit.current = false;
      setShowConfirmWindow(false);
      setPendingChange(false);
      setEdit(false); // Close the edit page of drawer and go back to readOnly details
    } else {
      closeDrawer();
    }
  }

  //TODO: Create API function to update user in DB

  // const handleOnClickSave = useCallback(() => {
  //   fetch(`${Config.apiRootURL}/sourcefeed/${editedUser.id}`, {
  //     method: 'PUT',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(editedUser),
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       // If response is ok, continue with setting states
  //       setRefreshData((prevRefreshData) => !prevRefreshData);
  //       setPendingChange(false);
  //       updateParentRow(editedUser.id);
  //       setEdit(!edit); // Toggle the edit mode state
  //     })
  //     .catch((error) => {
  //       console.error('Error while saving:', error);
  //       // Handle error if needed
  //     });
  // }, []);

  const onSubmit: SubmitHandler<User> = (data) => {
    let userDataWrapper = new User(data);
    console.log(userDataWrapper);
    // fetch(`${Config.apiRootURL}/sourcefeed`, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(userDataWrapper),
    // })
    //   .then((response) => {
    //     if (!response.ok) {
    //       window.alert(response.status + 'Failed to create source feed.');
    //       throw new Error('Network response was not ok');
    //     } else response.ok;
    //     window.alert(response.status + ' Source feed created!');
    //     // onClose();
    //   })
    //   .catch((error) => {
    //     console.error('Error while saving:', error);
    //   });
  };

  return (
    <div
      ref={drawerRef}
      className={`shadow-[0_3px_10px_rgb(0,0,0,0.2)] fixed inset-y-0 right-0 max-w-full flex transition-all duration-500 ${
        drawerVisible ? 'translate-x-0' : 'translate-x-full'
      }`}
    >
      {' '}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="pt-16 w-screen max-w-lg md:max-w-lg h-full flex flex-col bg-shell shadow-xl">
            <div className="shadow-md bg-secondary border-b border-tertiary pl-6 pr-1 py-2 flex justify-between content-between ">
              <h2 className="text-foreground text-lg font-bold pr-2 py-1 h-7 flex space-x-2">
                {edit ? 'User - Edit' : 'User - Details'}
              </h2>

              <div className="pl-3 pr-2 py-1 h-7 flex space-x-2">
                {/* Refresh Button */}
                <button
                  type="button"
                  className=" z-50 rounded-md text-primary hover:text-gray-500"
                  onClick={handleOnClickRefresh}
                >
                  <span className="sr-only">Refresh</span>
                  <ArrowPathIcon
                    className="h-6 w-6"
                    aria-hidden="true"
                  />
                </button>
                {/* Edit / Save Button */}
                {edit ? (
                  <button
                    type="submit"
                    className=" z-50  pl-1 rounded-md text-primary hover:text-gray-500 focus:outline-none"
                    // onClick={handleOnClickSave}
                    disabled={!pendingChange}
                  >
                    <span className="sr-only">Save</span>
                    <DocumentCheckIcon
                      className={pendingChange ? 'h-6 w-6 text-green-600' : 'h-6 w-6 text-green-600 opacity-30'}
                      aria-hidden="true"
                    />
                  </button>
                ) : (
                  <button
                    type="button"
                    className=" z-50  pl-1 rounded-md text-primary hover:text-gray-500 focus:outline-none"
                    onClick={() => setEdit(true)}
                  >
                    <span className="sr-only">Edit</span>
                    <PencilIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                    />
                  </button>
                )}
                {/* Close Button */}
                {edit ? (
                  <button
                    type="button"
                    className=" z-50 pl-1 rounded-md text-primary hover:text-gray-500 focus:outline-none"
                    onClick={handleEditDrawerCloseButtonClick}
                  >
                    <span className="sr-only">Close panel</span>
                    <XMarkIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                    />
                  </button>
                ) : (
                  <button
                    type="button"
                    className=" z-50 pl-1 rounded-md text-primary hover:text-gray-500 focus:outline-none"
                    onClick={() => closeDrawer()}
                  >
                    <span className="sr-only">Close panel</span>
                    <XMarkIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                    />
                  </button>
                )}
              </div>
            </div>
            <div className="relative flex-1">
              <div className="absolute inset-0 px-4 sm:px-6 overflow-y-auto overflow-x-hidden">
                {/* Conditional rendering based on edit mode */}
                {edit ? (
                  <UserDetailsEditDrawer
                    setPendingChanges={handlePendingChanges}
                    refreshData={refreshData}
                    userId={userId}
                  />
                ) : (
                  <UserDetailsReadOnlyDrawer
                    refreshData={refreshData}
                    userId={userId}
                  />
                )}
              </div>
            </div>
          </div>{' '}
        </form>
      </FormProvider>
      <Transition.Root
        show={showConfirmWindow}
        as={Fragment}
      >
        <Dialog
          className="fixed inset-y-10 inset-x-0 z-10 "
          onClose={() => setShowConfirmWindow(false)}
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This is the dialog box */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className=" inline-block align-bottom bg-shell rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-shell sm:px-4 pt-5 pb-4 sm:p-6 sm:pb-4 px-8 text-center sm:text-left text-foreground">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3  text-center sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-foreground"
                      >
                        Are you sure...
                      </Dialog.Title>
                    </div>
                  </div>
                  you want to leave without saving changes?
                </div>
                <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary/70 focus:outline-none  sm:ml-3 sm:w-auto"
                    onClick={handleConfirmLeaveDialogClose}
                  >
                    Leave
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-gray-500 text-base font-medium text-white hover:bg-gray-500/70 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto "
                    onClick={() => setShowConfirmWindow(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
