import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type MultiselectProps = {
  dbList: any[];
  preAssignedList: any[];
  onSelectionChange: (updatedSelection: any[]) => void;
  errorInSelection?: boolean;
};

export default function MultiselectList({
  preAssignedList,
  dbList,
  onSelectionChange,
  errorInSelection,
}: MultiselectProps) {
  const [selected, setSelected] = useState<any[]>([]);

  //Look for any new additions to the preAssignedList and reflect changes in Listbox.Button and .Options (selected)
  useEffect(() => {
    findListMatches(dbList, preAssignedList);
  }, [preAssignedList]);

  function findListMatches(mainList: any[], assignedList: any[]) {
    let tempList: any[] = [];
    assignedList.map((s) => {
      let result = mainList.find((p) => p.id == s.id);
      if (result != null) tempList.push(result);
    });
    setSelected(tempList);
  }

  //Parent component will have a passed handler-function that will add/remove selected items.
  function selectedChanged(newlySlected: any[]) {
    onSelectionChange(newlySlected);
  }

  return (
    <>
      <Listbox
        value={selected}
        onChange={selectedChanged}
        multiple
      >
        {({ open }) => (
          <>
            <div className="relative mt-2 ">
              <Listbox.Button
                className={` ${
                  errorInSelection ? 'border border-red-500' : 'border-0'
                } h-auto min-h-[2.3rem] relative w-full cursor-default rounded-md bg-background/50 py-1.5 pl-3 pr-10 text-foreground  shadow-sm ring-1 ring-inset ring-secondary focus:outline-none sm:text-sm sm:leading-6`}
              >
                {selected.map((item, index) => (
                  <span
                    key={index}
                    className="text-foreground drop-shadow-sm bg-secondary text-sm sm:text-sm font-medium mr-1 my-1 rounded-md inline-flex px-2 py-1 ring-1 ring-tertiary"
                  >
                    {item.name}
                  </span>
                ))}
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-72 w-full overflow-auto rounded-md bg-secondary py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {dbList.map((item) => (
                    <Listbox.Option
                      key={item.id}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-primary text-white' : 'text-foreground',
                          'relative cursor-default select-none py-2 pl-8 pr-4',
                        )
                      }
                      value={item}
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                            {item.name}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-primary',
                                'absolute inset-y-0 left-0 flex items-center pl-1.5',
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </>
  );
}
