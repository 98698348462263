import Category from './Category';
import User from './User';

class SourceFeed {
  /// <summary>
  /// The user's unique identification number for a SourceFeed
  /// </summary>
  id: number;

  /// <summary>
  /// A 'pointer' to an associated organization's ID
  /// </summary>
  organizationID: number;

  /// <summary>
  /// Organization object used for the repository for which the user belongs to
  /// </summary>
  organizationName: string;

  /// <summary>
  /// name of user who created sourcefeed entry
  /// </summary>
  public author: User | null;

  /// <summary>
  /// The author's unique identification number
  /// </summary>
  authorID: string;

  ///<summary>
  ///a title of the sourcefeed
  /// </summary>
  name: string;

  /// <summary>
  /// URL to domain of SourceFeed
  /// </summary>
  sourceDomain: string;

  /// <summary>
  /// URL for SourceFeed
  /// </summary>
  public locationURL: string;

  /// <summary>
  /// Describes the locational relevance of the of the SourceFeed's content
  /// </summary>
  public regionTypeID: number;

  /// <summary>
  /// Geographic/locational info
  /// </summary>
  // public location: string;

  /// <summary>
  /// Gets the RSS format type the SourceFeed is in
  /// </summary>
  public formatTypeID: number;

  /// <summary>
  /// Format version of the SourceFeed
  /// </summary>
  public formatVersion: string;

  /// <summary>
  /// DateTime indicating the last time feedcrawler succesfully crawled/pulled Feed's data
  /// </summary>
  lastSyncTime: Date;

  /// <summary>
  /// A date object that represents when the SourceFeed was updated in system
  /// </summary>
  lastUpdated: Date;

  /// <summary>
  /// Date for when SourceFeed entry was initially created
  /// </summary>
  dateCreated: Date;

  /// <summary>
  /// A bool key that shows whether SourceFeed is active or inactive
  /// </summary>
  enabled: boolean;

  /// <summary>
  /// Indicates what language the rss feed is in. Default 1 is English.
  /// </summary>
  languageCodeID: number;

  /// <summary>
  /// HTTP Status code of Source Feed URL
  /// </summary>
  statusCode: number;

  /// <summary>
  /// Represents how frequently the RSS feed is updated (in minutes).
  /// </summary>
  crawlInterval: number;

  /// <summary>
  /// Represents the LATEST time in day that RSS feed can be crawled.
  /// </summary>
  crawlWindowMax: Date | null;

  /// <summary>
  /// Represents the EARLIEST time in day that RSS feed can be crawled.
  /// </summary>
  crawlWindowMin: Date | null;

  /// <summary>
  /// List of categories that the sourcefeed falls under
  /// </summary>
  categories: Category[];

  /// <summary>
  /// Represents the activity status of the source feed during feed processing.
  /// </summary>
  ActivtyStatus?: string;

  public constructor(json: any) {
    this.id = json.id || -1;
    this.organizationID = json.organizationID || 1;
    this.organizationName = json.organizationName || '';
    this.author = json.author ? new User(json.author) : null;
    this.authorID = json.authorID || '672E4F07-DCD4-47DB-A010-F541436141A3'; //Tobias
    this.name = json.name || '';
    this.sourceDomain = json.sourceDomain || '';
    this.locationURL = json.locationURL || '';
    this.regionTypeID = json.regionTypeID || 1;
    //this.location = json.location || '';
    this.formatTypeID = json.formatType || 1;
    this.formatVersion = json.formatVersion || 'formatVersion';
    this.lastSyncTime = json.lastSyncTime ? new Date(json.lastSyncTime) : new Date();
    this.lastUpdated = json.lastUpdated ? new Date(json.lastUpdated) : new Date();
    this.dateCreated = json.dateCreated ? new Date(json.dateCreated) : new Date();
    this.enabled = json.enabled || true;
    this.languageCodeID = json.languageCodeID || 1;
    this.statusCode = json.statusCode || -1;
    this.crawlInterval = json.crawlInterval || 30;
    this.crawlWindowMax = json.crawlWindowMax ? new Date(json.crawlWindowMax) : null;
    this.crawlWindowMin = json.crawlWindowMin ? new Date(json.crawlWindowMin) : null;
    this.categories = json.categories ? json.categories : [];
    this.ActivtyStatus = json.ActivtyStatus || '';
  }
}

export default SourceFeed;
