import { useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { CircularProgress, ListItem, ListItemText } from '@mui/material';
import EmailVerificationCheck from '../../SharedComponents/EmailVerificationCheck/EmailVerificationCheck';
import InitialsAvatar from '../../Users/UserProfileDrawerDetails/Components/InitialsAvatar';
import User from '../../Models/User';
import '../UserProfile.styles.css';
import KeyIcon from '@mui/icons-material/Key';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

type UserProfileBrowserViewProps = {
  userData: User;
};

const format: Intl.DateTimeFormatOptions = {
  month: '2-digit',
  year: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit'

}

const UserProfileBrowserView = ({ userData }: UserProfileBrowserViewProps) => {
  const [user, setUser] = useState<User>(userData);

  function renderRow(props: ListChildComponentProps) {
    const { index } = props;
    const item = user.activityLogs[index];

    return (
      <ListItem style={{ ...props.style, paddingLeft: 0 }} key={index} component="li">
        <ListItemText primary={`${(Intl.DateTimeFormat("en-us", format).format(item.timestamp)).replace(',', '')}:`} style={{ maxWidth: 'fit-content', paddingRight: 30 }} />
        <ListItemText primary={`${item.access}`} style={{ maxWidth: 'fit-content', paddingRight: 15 }} />
      </ListItem>
    )
  }

  if (!user) {
    return (
      <Container maxWidth="md" style={{ marginTop: '50px', textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }
  return (
    <Container maxWidth="md" sx={{ marginTop: { xs: 5, sm: 5, md: 10 } }}>
      <Paper className="userProfile" sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <div>
              <InitialsAvatar width={175} height={175} fullName={user.getFullName()} />
              <Typography variant="h4" sx={{ marginTop: 2 }} fontWeight="bold">
                {user.getFullName()}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={8}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flexGrow: 1, marginTop: 10, marginBottom: 10 }}>
                <Typography variant="h5" fontWeight="bold">
                  Username
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '1.3rem' }}>{user.username}</Typography>
              </div>
              <div style={{ flexGrow: 1, marginTop: 10, marginBottom: 10 }}>
                <Typography variant="h5" fontWeight="bold">
                  Date Created
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '1.2rem' }}>{user.created.toLocaleDateString()}</Typography>
              </div>
            </div>

            <div style={{ flexGrow: 1, marginTop: 10, marginBottom: 10 }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography variant="h5" fontWeight="bold">
                  Email
                </Typography>
                <EmailVerificationCheck verified={user.emailVerified} />
              </div>
              <Typography variant="body1" sx={{ fontSize: '1.3rem' }}>{user.email}</Typography>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 2 }}>
              <div style={{ flexGrow: 1 }}>
                <Typography variant="h5" fontWeight="bold">
                  Password
                </Typography>
                <Button size="medium" variant="outlined" sx={{ verticalAlign: 'middle', fontWeight: 'bold' }}>
                  Reset <KeyIcon sx={{ verticalAlign: 'middle', paddingLeft: .5, paddingBottom: .1 }} />
                </Button>
              </div>
              <div style={{ flexGrow: 1 }}>
                <Typography variant="h5" fontWeight="bold">
                  Last Changed
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '1.2rem' }}>{user.created.toLocaleDateString()}</Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </Paper>

      <Typography variant="h5" fontWeight="bold">
        Organization
      </Typography>

      <Paper className="userProfile" sx={{ padding: 3, marginTop: 1 }}>
        <Typography variant="h4" fontWeight="bold" sx={{ paddingBottom: 1 }}>{user.organization?.organizationName}</Typography>

        <Container>
          <Typography variant="h5" fontWeight="bold" sx={{ marginTop: 2, paddingBottom: 1 }}>
            Roles
          </Typography>
          <Container sx={{ maxHeight: 300, overflow: 'auto' }}>
            {user.roles && user.roles.map((role: { name: string }, index) => (
              <div key={index} >
                <Typography variant="subtitle1" sx={{ fontSize: '1.2rem' }}>
                  • {role.name}
                </Typography>
              </div>
            ))}
            {!user.roles &&
              <Typography variant="subtitle1" sx={{ fontSize: '1.2rem' }}>
                • None
              </Typography>}
          </Container>
        </Container>
      </Paper>

      <Typography variant="h5" fontWeight="bold">
        Activity Log
      </Typography>
      <Paper sx={{ padding: 3, marginTop: 1 }}>
        <Container sx={{ maxHeight: 300 }}>
          <FixedSizeList
            height={300}
            width="maxWidth"
            itemSize={24}
            itemCount={user.activityLogs.length}
            overscanCount={10}
            style={{ overflow: 'auto', overflowX: 'hidden' }}
          >
            {renderRow}
          </FixedSizeList>
        </Container>
      </Paper>
    </Container>
  );
};

export default UserProfileBrowserView;
