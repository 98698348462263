import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Config from '../../AppConfig.json';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import './UserProfileDrawerDetails.styles.css';
import User from '../../Models/User';
import UserActivityLog from '../../Models/UserActivityLog';
import List from '@mui/material/List';
import React, { useEffect, useState } from 'react';
import { render } from '@testing-library/react';
import Tooltip from '@mui/material/Tooltip';
import { alignProperty } from '@mui/material/styles/cssUtils';
import { AlignHorizontalLeft, BorderColor, BorderStyle, Padding, Style, WidthNormal } from '@mui/icons-material';

type props = {
  user: User;
};

const format: Intl.DateTimeFormatOptions = {
  month: '2-digit',
  year: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
};

const UserProfileBottom = ({ user }: props) => {
  const [logs, setLogs] = useState<UserActivityLog[]>([]);

  useEffect(() => {
    fetch(Config.apiRootURL + '/organization/' + user.organizationID + '/user/' + user.id + '/log/100')
      //fetch("http://localhost:5134/api/v1" + "/organization/"+ user.organizationID +"/user/" + user.id + "/log/100")
      .then((response) => response.json())
      .then((json) => {
        var entries = json.map((item: UserActivityLog) => {
          return { ...item, timestamp: new Date(item.timestamp) };
        });
        setLogs(entries);
      });
  }, [user]);

  function renderRow(props: ListChildComponentProps) {
    const { index } = props;
    const item = logs[index];

    return (
      <ListItem
        style={{ ...props.style, paddingLeft: 0 }}
        key={index}
        component="li"
      >
        <ListItemText
          primary={`${Intl.DateTimeFormat('en-us', format).format(item.timestamp).replace(',', '')}:`}
          style={{ maxWidth: 'fit-content', paddingRight: 30 }}
        />
        <ListItemText
          primary={`${item.access}`}
          style={{ maxWidth: 'fit-content', paddingRight: 15 }}
        />
      </ListItem>
    );
  }

  return (
    <Box
      sx={{ height: 'fit-content' }}
      className="activityLog"
    >
      <h4>Activity log:</h4>
      <FixedSizeList
        height={350}
        width="maxWidth"
        itemSize={18}
        itemCount={logs.length}
        overscanCount={10}
        style={{ overflow: 'scroll', overflowX: 'hidden' }}
      >
        {renderRow}
      </FixedSizeList>
    </Box>
  );
};

export default UserProfileBottom;
