import React, { FC } from 'react';
import ToggleButton from '../../components/Controls/ToggleButton/ToggleButton';
import { Link } from 'react-router-dom';

interface StylingProps {}

const Styling: FC<StylingProps> = () => (
  <div className="flex items-center justify-center min-h-screen px-4 sm:px-0 bg-back">
    <div className="relative flex flex-col sm:flex-row justify-center items-center p-3 rounded-md outline-double outline-foreground">
      <div className="absolute top-3 right-3">
        <ToggleButton />
      </div>
      <div className="flex justify-center items-center p-4 sm:p-12">
        <article className="prose sm:prose-lg lg:prose-xl text-for">
          <h1 className="text-foreground">UI/UX Styling</h1>
          <p className="text-foreground">
            Tailwind CSS is a utility-first CSS framework for rapidly building custom user interfaces. Instead of
            providing a set of predefined components like Bootstrap or Material UI, Tailwind allows developers to
            construct their own unique design by combining a series of small, responsive CSS classes directly in their
            markup.
          </p>
          <ul className="list-disc list-inside">
            <li>
              <Link
                to="https://tailwindcss.com/docs"
                className="text-foreground"
              >
                Tailwind CSS
              </Link>
              <span className="text-foreground">
                : The official documentation is a great place to start. It provides a comprehensive overview of the
                framework, including installation instructions, a guide to the utility-first workflow, and detailed
                documentation of every utility.
              </span>
            </li>
            <li>
              <Link
                to="https://tailwindui.com/"
                className="text-foreground"
              >
                Tailwind UI
              </Link>
              <span className="text-foreground">
                : A component library created by the makers of Tailwind CSS. It provides professionally designed, fully
                responsive HTML components that are ready to drop into your Tailwind projects.
              </span>
            </li>
          </ul>
          <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4 items-center p-6 rounded-lg mt-6 bg-foreground">
            <span className="flex flex-col items-center">
              <div className="rounded-full bg-shell p-1 w-20 h-20 shadow-md" />
              <article className="text-center text-background">Shell</article>
            </span>
            <span className="flex flex-col items-center">
              <div className="rounded-full bg-primary p-1 w-20 h-20 shadow-md" />
              <article className="text-center text-background">Primary</article>
            </span>
            <span className="flex flex-col items-center">
              <div className="rounded-full bg-secondary p-1 w-20 h-20 shadow-md" />
              <article className="text-center text-background">Secondary</article>
            </span>
            <span className="flex flex-col items-center">
              <div className="rounded-full bg-tertiary p-1 w-20 h-20 shadow-md" />
              <article className="text-center text-background">Tertiary</article>
            </span>
            <span className="flex flex-col items-center">
              <div className="rounded-full bg-alert p-1 w-20 h-20 shadow-md" />
              <article className="text-center text-background">Alert</article>
            </span>
            <span className="flex flex-col items-center">
              <div className="rounded-full bg-warning p-1 w-20 h-20 shadow-md" />
              <article className="text-center text-background">Warning</article>
            </span>
            <span className="flex flex-col items-center">
              <div className="rounded-full bg-information p-1 w-20 h-20 shadow-md" />
              <article className="text-center text-background">Information</article>
            </span>
          </div>
        </article>
      </div>
    </div>
  </div>
);

export default Styling;
