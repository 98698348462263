enum FormatType {
  RssXml = 1,
  Atom = 2,
  JSON = 3,
}

export default FormatType;

export function getFormatTypeString(value: number): string {
  switch (value) {
    case FormatType.RssXml:
      return 'RssXml';
    case FormatType.Atom:
      return 'Atom';
    case FormatType.JSON:
      return 'JSON';
    default:
      return 'Unknown';
  }
}
