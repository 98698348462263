import { useEffect, useState } from 'react';
import Button from '../Controls/PrimaryButton/PrimaryButton';
import User from '../../models/User';
import Config from '../../app-config.json';

interface AccountProps {
  orgId: string;
  userId: string;
}

export default function Account({ orgId, userId }: AccountProps): JSX.Element {
  const [user, setUser] = useState<User>();

  // Fetch user data
  useEffect(() => {
    fetch(Config.apiRootURL + `/organization/${orgId}/user/${userId}`)
      .then((response) => response.json())
      .then((json) => {
        const userData = new User(json);
        setUser(userData);
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
        // Todo: Handle the error as needed
      });
  }, [userId, orgId]);

  return (
    <div>
      <div>
        <main>
          <h1 className="sr-only">Account Settings</h1>

          <div className="divide-y divide-white/5">
            <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 sm:px-6 md:grid-cols-3 lg:px-8">
              <div>
                <h2 className="text-base font-semibold leading-7 text-foreground">Personal Information</h2>
                <p className="mt-1 text-sm leading-6 text-foreground">
                  Your personal information and account settings.
                </p>
              </div>

              <form className="md:col-span-2">
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 text-foreground"
                    >
                      First name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        className={`block w-full rounded-md border-0 bg-foreground/5 p-1.5 text-foreground shadow-sm sm:text-sm sm:leading-6 outline-none ${user ? '' : 'animate-pulse-slow'}`}
                        value={user ? user.firstName : ''}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium leading-6 text-foreground"
                    >
                      Last name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        autoComplete="family-name"
                        className={`block w-full rounded-md border-0 bg-foreground/5 p-1.5 text-foreground shadow-sm sm:text-sm sm:leading-6 outline-none ${user ? '' : 'animate-pulse-slow'}`}
                        value={user ? user.lastName : ''}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-foreground"
                    >
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        className={`block w-full rounded-md border-0 bg-foreground/5 p-1.5 text-foreground shadow-sm sm:text-sm sm:leading-6 outline-none ${user ? '' : 'animate-pulse-slow'}`}
                        value={user ? user.email : ''}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="username"
                      className="block text-sm font-medium leading-6 text-foreground"
                    >
                      Username
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="username"
                        id="username"
                        autoComplete="username"
                        className={`block w-full rounded-md border-0 bg-foreground/5 p-1.5 text-foreground shadow-sm sm:text-sm sm:leading-6 outline-none ${user ? '' : 'animate-pulse-slow'}`}
                        value={user ? user.username : ''}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 mt-16 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
              <div>
                <h2 className="text-base font-semibold leading-7 text-foreground">Change password</h2>
                <p className="mt-1 text-sm leading-6 text-foreground">
                  Update your password associated with your account.
                </p>
              </div>

              <form className="md:col-span-2">
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                  <div className="col-span-full">
                    <label
                      htmlFor="current-password"
                      className="block text-sm font-medium leading-6 text-foreground"
                    >
                      Current password
                    </label>
                    <div className="mt-2">
                      <input
                        id="current-password"
                        name="current_password"
                        type="password"
                        autoComplete="current-password"
                        className="block w-full rounded-md border-0 bg-foreground/5 p-1.5 text-foreground shadow-sm sm:text-sm sm:leading-6 outline-none hover:bg-foreground/10"
                      />
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="new-password"
                      className="block text-sm font-medium leading-6 text-foreground"
                    >
                      New password
                    </label>
                    <div className="mt-2">
                      <input
                        id="new-password"
                        name="new_password"
                        type="password"
                        autoComplete="new-password"
                        className="block w-full rounded-md border-0 bg-foreground/5 p-1.5 text-foreground shadow-sm sm:text-sm sm:leading-6 outline-none hover:bg-foreground/10"
                      />
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="confirm-password"
                      className="block text-sm font-medium leading-6 text-foreground"
                    >
                      Confirm password
                    </label>
                    <div className="mt-2">
                      <input
                        id="confirm-password"
                        name="confirm_password"
                        type="password"
                        autoComplete="new-password"
                        className="block w-full rounded-md border-0 bg-foreground/5 p-1.5 text-foreground shadow-sm sm:text-sm sm:leading-6 outline-none hover:bg-foreground/10"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-8 flex">
                  <Button
                    type="submit"
                    text="Save"
                  />
                </div>
              </form>
            </div>

            <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
              <div>
                <h2 className="text-base font-semibold leading-7 text-foreground">Log out other sessions</h2>
                <p className="mt-1 text-sm leading-6 text-foreground">
                  Please enter your password to confirm you would like to log out of your other sessions across all of
                  your devices.
                </p>
              </div>

              <form className="md:col-span-2">
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                  <div className="col-span-full">
                    <label
                      htmlFor="logout-password"
                      className="block text-sm font-medium leading-6 text-foreground"
                    >
                      Your password
                    </label>
                    <div className="mt-2">
                      <input
                        id="logout-password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        className="block w-full rounded-md border-0 bg-foreground/5 p-1.5 text-foreground shadow-sm sm:text-sm sm:leading-6 outline-none hover:bg-foreground/10"
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-8 flex">
                  <Button
                    type="submit"
                    text="Log out other sessions"
                  />
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
