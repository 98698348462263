import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// Styles
import './LegacyApp.css';
// Material
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
// Componenets
import Error from './Error/Error';
import Home from './LegacyHome/Home';
import LogIn from './LogIn/LogIn';
import Register from './Register/Register';
import UsersListView from './Users/List/UsersListView';
import SourceFeedsListView from './SourceFeed/List/SourceFeedsListView';
import UserProfile from './UserProfile/UserProfile';
import AppShell from './Navigation/AppShell';
import Sidebar from './Navigation/Sidebar';
import ResponsiveAppBar from './Navigation/ResponsiveAppBar';

export function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link
        color="inherit"
        href="http://localhost:3000/"
      >
        BlackTine LLC
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function LegacyApp() {
  return (
    <html>
      {/* <Router>
             <Routes>
                 <Route path="/" element={<Home />} />
                 <Route path="/login" element={<LogIn />} />
                 <Route path="/register" element={<Register />} />             
                 <Route path="*" element={<Error />} />
                 <Route path="/users/list/userslistview" element={<UsersListView />} />                
                 <Route path="/SourceFeed/List/SourceFeedsListView" element={<SourceFeedsListView />} />
                 <Route path="/UserProfile/UserProfile" element={<UserProfile/>}/>                
                 <Route path="/Navigation/AppShell" element={<AppShell><SourceFeedsListView /></AppShell>} />
                 <Route path="/Navigation/SideBar" element={<Sidebar open={true} onCollapse={() => { }} onExpand={() => { }}/>} />
                 <Route path="/Navigation/ResponsiveAppBar" element={<ResponsiveAppBar />} />
             </Routes>
         </Router> */}
    </html>
  );
}

export default LegacyApp;
