import BaseListView from './BaseListView';
import SourceFeed from '../../../models/SourceFeed';
import { RssIcon } from '@heroicons/react/24/outline';
import ReactErrorBoundary from '../../Warnings/ReactErrorBoundary';
import SearchBar from '../../SearchBar/SearchBar';
import SourceFeedDetailsDrawer from '../../Drawers/SourceFeedDetailsDrawer/SourceFeedDetailsParentDrawer';
import { useCallback, useState } from 'react';
import CreateNewSourceFeedDialogue from '../../../views/CreateNew/CreateNewSourceFeedDialog';

export default function BaseListViewSourceFeedParentTest() {
  const [rowSelectedId, setRowSelectedId] = useState(-1);
  const [isEdited, setisEdited] = useState(false); //updateBool boolean that is triggered when updateRow()
  const [createNewSourceFeedTrigger, setCreateNewSourceFeedTrigger] = useState(false);

  const onRowClick = useCallback((id: number) => {
    setRowSelectedId(id);
  }, []);

  const closeDrawer = useCallback(() => {
    setRowSelectedId(-1); // Reset the selected row ID to close the drawer
  }, []);

  //A function passed to a child drawer component to fire off anytime the pulled Source Feed is updated.
  const updateRow = useCallback(() => {
    setisEdited(!isEdited);
  }, []);

  function createNewSourceFeed() {
    setCreateNewSourceFeedTrigger(true);
  }

  function onDialogClose() {
    setCreateNewSourceFeedTrigger(false);
  }

  return (
    <div>
      <ReactErrorBoundary>
        <BaseListView<SourceFeed>
          apiURL="/sourcefeed/?incDisabled=true"
          title="Source Feed"
          desc="A list of all source feeds including their names, the URLs of their source domains, their locations, last synced times, and enabled statuses."
          columns={['Name', 'Source Domain', 'Location', 'Last Synced', 'Enabled']}
          rowData={['name', 'sourceDomain', 'locationURL', 'lastSyncTime', 'enabled']}
          searchBar={
            <SearchBar
              searchIcon={<RssIcon />}
              searchBarPlaceholder="Search source feeds..."
              apiURL="/sourcefeed/?incDisabled=true"
              searchFields={['name', 'sourceDomain']}
            />
          }
          onRowSelect={onRowClick}
          onAddButtonClick={createNewSourceFeed}
          isEdited={isEdited}
        />
        {rowSelectedId > 0 && (
          <SourceFeedDetailsDrawer
            id={rowSelectedId}
            closeDrawer={closeDrawer}
            updateParentRow={updateRow}
          />
        )}
        {createNewSourceFeedTrigger && <CreateNewSourceFeedDialogue onClose={onDialogClose} />}
      </ReactErrorBoundary>
    </div>
  );
}
