import React, { FC, ReactElement, useState } from 'react';
import Config from '../../app-config.json';
import useDarkModeStore from '../../stores/useDarkModeStore';

interface SearchBarProps {
  searchIcon: ReactElement;
  searchBarPlaceholder: string;
  apiURL: string;
  searchFields: string[];
  offset?: number;
  limit?: number;
  onSearchResults?: (results: Data[]) => void;
}

interface Data {
  [key: string]: any;
}

const SearchBar: FC<SearchBarProps> = ({ searchIcon, searchBarPlaceholder, apiURL, searchFields, onSearchResults }) => {
  const { darkMode } = useDarkModeStore() as { darkMode: boolean };

  // State variable to store the current search term
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);

    if (searchTerm.trim() !== '') {
      // Construct the API URL with the search term
      const url = `${Config.apiRootURL}${apiURL}&searchTerm=${encodeURIComponent(searchTerm)}`;

      // Make an API request to fetch search results
      fetch(url)
        .then((response) => response.json())
        .then((json) => {
          // Filter the search results based on the specified search fields
          const filteredResults = json.results.filter((item: any) => {
            return searchFields.some((field) => {
              const fieldValue = getNestedValue(item, field);
              return fieldValue && fieldValue.toString().toLowerCase().includes(searchTerm.toLowerCase());
            });
          });

          // Invoke the onSearchResults callback with the filtered results
          if (onSearchResults) {
            console.log(filteredResults);
            onSearchResults(filteredResults);
          }
        })
        .catch((error: any) => {
          console.error('Error fetching search results:', error);
        });
    } else {
      // If the search term is empty, invoke the onSearchResults callback with an empty array
      if (onSearchResults) {
        onSearchResults([]);
      }
    }
  };

  // Helper function to get the value of a nested field
  const getNestedValue = (obj: any, field: string): any => {
    const fields = field.split('.');
    let value = obj;
    for (const f of fields) {
      if (value && value.hasOwnProperty(f)) {
        value = value[f];
      } else {
        return undefined;
      }
    }
    return value;
  };

  return (
    <div className="justify-start flex">
      <div className="mr-4 sm:mr-0 w-full mt-4 flex rounded-md shadow-sm">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            {React.cloneElement(searchIcon, {
              className: `h-5 w-5 ${darkMode ? 'text-background' : 'text-foreground'}`,
              'aria-hidden': 'true',
            })}
          </div>
          <input
            type="text"
            name="search"
            id="search"
            className={`block w-full rounded-md border-0 py-1.5 pl-10 pr-3 sm:text-sm sm:leading-6 ${darkMode ? 'bg-foreground text-background placeholder:text-background' : 'bg-slate text-foreground placeholder:text-foreground'}`}
            placeholder={searchBarPlaceholder}
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
