import Avatar from '@mui/material/Avatar';

interface Props {
    fullName: string;
    width: number;
    height: number;
}

const InitialsAvatar = (props: Props) => {
    function stringToColor(string: string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name: string) {
        return {
            sx: {
                bgcolor: stringToColor(name),
                width: {xs: (props.width/1.7), sm: (props.width/1.3), md: props.width},
                height:  {xs: (props.height/1.7), sm: (props.height/1.3), md: props.height},
                fontSize:  {xs: (props.height/2.9), sm: (props.height/2.3), md: (props.height/2)}
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    return (
        <Avatar {...stringAvatar(props.fullName)} />
    );

}

export default InitialsAvatar;

