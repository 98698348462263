import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Settings from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#EFEFEF',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
});

function ResponsiveAppBar() {
  return (
    <ThemeProvider theme={theme}>
      <AppBar position="fixed" color="primary" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <img src="/tempLogo.png" alt="Logo" style={{ padding: '0.5rem', maxWidth: '4rem', maxHeight: '60%' }} />
            <Typography variant="h5" noWrap component="div" sx={{ fontWeight: 700, color: '#123550' }}>
              IceBreaker
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton color="inherit" sx={{ color: '#123550' }}>
              <Settings sx={{ fontSize: 28 }} />
            </IconButton>
            <IconButton color="inherit" sx={{ color: '#123550' }}>
              <AccountCircle sx={{ fontSize: 42 }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {/* This empty Toolbar component is necessary to offset the content below the fixed AppBar */}
      <Toolbar />
    </ThemeProvider>
  );
}

export default ResponsiveAppBar;
