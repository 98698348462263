import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from "react";
import Config from '../../AppConfig.json';
import "./UserProfileDrawerDetails.styles.css";

import Tooltip from "@mui/material/Tooltip";

import User from "../../Models/User";
import EmailVerificationCheck from '../../SharedComponents/EmailVerificationCheck/EmailVerificationCheck';

type userDetailTopProps = {
    user: User;
    orgName: string;
}

interface Role {
    id: number;
    name: string;
}

const UserProfileMiddle = ({ user, orgName }: userDetailTopProps) => {

    return (
        <Grid container
            spacing={0}
            direction="row"
            className="user-middle-container"
        >
            <Grid item xs={6} >
                <Grid container spacing={0} direction="column" justifyContent="flex-start">
                    <Grid item xs={3}>
                        <div className="subjectHeader">
                            <h4>Username</h4>
                            <div>{user.username}</div>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="subjectHeader">
                            <h4>Organization</h4>
                            <div>{orgName}</div>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="subjectHeader">
                            <h4>Password</h4>
                            <div>{user.passwordLastChanged.toLocaleString()}</div>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="subjectHeader">
                            <h4>Date Created</h4>
                            <div>{user.created.toLocaleString()}</div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container spacing={0} direction="column" justifyContent="flex-start">
                    <Grid item xs={4}>
                        <div className="subjectHeader">
                            <h4>
                                Email
                                <EmailVerificationCheck verified={user.emailVerified} />
                            </h4>
                            <Tooltip title={user.email} placement="top-start">
                                <div className='emailText'>{user.email}</div>
                            </Tooltip>
                        </div>
                    </Grid>
                    <Grid item xs={8}>
                        <div className="subjectHeader">
                            <h4>Roles</h4>
                            {user.roles && user.roles.length > 0 && (
                                <div>
                                    {user.roles.map((role: { name: string }, index) => (
                                        <div key={index}>{role.name}</div>
                                    ))}
                                </div>
                            )}
                             {!user.roles && (
                                <div>                                    
                                    <div>None</div>
                                  
                                </div>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default UserProfileMiddle;