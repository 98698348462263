import Tooltip from "@mui/material/Tooltip";
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React from "react";

type VerificationCheckProps = {
    verified: boolean;
}


const EmailVerificationCheck = ({ verified }: VerificationCheckProps) => {
    return (
        <React.Fragment>
            {verified &&
                <Tooltip title="Email verified" placement="right-end">
                    <CheckCircleIcon fontSize="inherit"
                        color="success"
                        style={{ paddingLeft: '2px' }} 
                    />
                </Tooltip>
            }

            {!verified &&
                <Tooltip title="Email not verified" placement="right-end">
                    <ErrorIcon fontSize="inherit" color="warning" style={{ paddingLeft: '2px' }} />
                </Tooltip>
            }
        </React.Fragment>

    )

}

export default EmailVerificationCheck;