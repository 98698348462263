enum FormatType
{
    RssXml = 1, 
    JSON = 2,
    Atom = 3
}

export default FormatType;

export function getFormatTypeString(value: number): string {
    switch (value) {
        case FormatType.RssXml: return 'RssXml';
        case FormatType.JSON: return 'JSON';
        case FormatType.Atom: return 'Atom';
        default: return 'Unknown';
    }
}