import { useEffect, useState } from 'react';
import User from '../../models/User';
import Config from '../../app-config.json';
import UserActivityLog from '../../models/UserActivityLog';
import useDarkModeStore from '../../stores/useDarkModeStore';

interface AccountProps {
  orgId: string;
  userId: string;
}

interface TableProps {
  headers: string[];
  data: any[];
  renderRow: (item: any, index: number) => any[];
}

/**
 * Table component
 * This component renders a table with given headers and data.
 * It uses the renderRow function to render each cell of the table.
 * @param {object} props - The properties passed to the component.
 * @param {string[]} props.headers - The headers of the table.
 * @param {any[]} props.data - The data to be displayed in the table.
 * @param {(item: any, index: number) => any[]} props.renderRow - The function to render each cell.
 */
const Table: React.FC<TableProps & { darkMode: boolean }> = ({ headers, data, renderRow, darkMode }) => {
  return (
    <div className="px-4 sm:px-6 lg:px-8 w-11/12">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <div className="bg-shell rounded-t-lg">
                <div className="bg-shell rounded-t-lg flex">
                  {headers.map((header, index) => (
                    <div
                      key={index}
                      className={`py-2 px-4 text-left text-md sm:text-md font-semibold text-foreground flex-1 ${
                        index === headers.length - 1 ? 'rounded-tr-lg' : ''
                      }`}
                    >
                      {header}
                    </div>
                  ))}
                </div>
              </div>
              <div
                className={`overflow-auto max-h-[16rem] scrollbar-thin scrollbar-thumb-foreground/80
                ${darkMode ? 'scrollbar-track-foreground/5' : 'scrollbar-track-shell/80'}`}
              >
                <div className={`min-w-full divide-y ${darkMode ? 'divide-gray-700' : 'divide-gray-300'}`}>
                  {data.length === 0 ? (
                    <SkeletonRow
                      headers={headers}
                      darkMode={darkMode}
                    />
                  ) : (
                    data.map((item, index) => {
                      const rowItems = renderRow(item, index);
                      return (
                        <div
                          key={index}
                          className="flex divide-gray-200 bg-white"
                        >
                          {rowItems.map((item, id) => (
                            <div
                              key={id}
                              className={`whitespace-nowrap py-2 px-4 text-xs sm:text-sm flex-1 ${darkMode ? 'bg-background/95 text-slate-200' : 'bg-foreground/5 text-slate-800'}`}
                            >
                              {item}
                            </div>
                          ))}
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
              <div className="bg-shell w-full h-3 rounded-b-lg"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SkeletonRow = ({ headers, darkMode }: { headers: string[]; darkMode: boolean }) => {
  return (
    <div
      className={`animate-pulse-slow flex ${darkMode ? 'bg-foreground/5 divide-gray-700' : 'bg-background/95 divide-gray-300'}`}
    >
      {headers.map((_, index) => (
        <div
          key={index}
          className="whitespace-nowrap py-3 px-4 text-xs sm:text-sm font-medium text-gray-600 flex-1 flex"
        >
          <div className="flex space-x-2 animate-pulse">
            <span className="sr-only">Loading...</span>
            <div className="h-2 w-2 bg-gray-600 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
            <div className="h-2 w-2 bg-gray-600 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
            <div className="h-2 w-2 bg-gray-600 rounded-full animate-bounce"></div>
          </div>
        </div>
      ))}
    </div>
  );
};

/**
 * Security component
 * This component fetches and displays user information and activity logs.
 * It uses the Table component to display the data.
 * @param {object} props - The properties passed to the component.
 * @param {string} props.orgId - The ID of the organization.
 * @param {string} props.userId - The ID of the user.
 */
export default function Security({ orgId, userId }: AccountProps): JSX.Element {
  const [user, setUser] = useState<User>();
  const { darkMode } = useDarkModeStore() as { darkMode: boolean };

  useEffect(() => {
    fetch(Config.apiRootURL + `/organization/1/user/${userId}`)
      .then((response) => response.json())
      .then((json) => {
        const userData = new User(json);

        fetch(Config.apiRootURL + `/organization/${json.organizationID}/user/${json.id}/role`)
          .then((response) => response.json())
          .then((jsonRoles) => {
            userData.roles = jsonRoles;

            fetch(Config.apiRootURL + `/organization/${userId}/user/${userId}/log/100`)
              .then((response) => response.json())
              .then((json) => {
                var entries = json.map((item: UserActivityLog) => {
                  return { ...item, timestamp: new Date(item.timestamp) };
                });
                userData.activityLogs = entries;
                setUser(userData);
              })
              .catch((error) => {
                console.error('Error fetching user logs:', error);
                // TODO: Handle the error as needed
              });
          })
          .catch((error) => {
            console.error('Error fetching user roles:', error);
            // TODO: Handle the error as needed
          });
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
        // TODO: Handle the error as needed
      });
  }, [userId]);

  return (
    <main>
      <div className="divide-y divide-white/5">
        <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 sm:px-6 md:grid-cols-3 lg:px-8">
          <div>
            <h2 className="text-base font-semibold leading-7 text-foreground">Organization Information</h2>
            <p className="mt-1 text-sm leading-6 text-foreground">Your personal information and account settings.</p>
          </div>
          <form className="md:col-span-2">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
              <div className="col-span-full">
                <Table
                  headers={['Roles', 'Description']}
                  data={user?.roles || []}
                  renderRow={(role, index) => [role.name, role.description]}
                  darkMode={darkMode}
                />
              </div>
            </div>
          </form>
        </div>
        <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 mt-16 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
          <div>
            <h2 className="text-base font-semibold leading-7 text-foreground">Account Activity</h2>
            <p className="mt-1 text-sm leading-6 text-foreground">Update your password associated with your profile.</p>
          </div>
          <form className="md:col-span-2">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
              <div className="col-span-full">
                <Table
                  headers={['Action', 'Timestamp']}
                  data={user?.activityLogs || []}
                  renderRow={(log, index) => [log.access, log.timestamp.toLocaleString()]}
                  darkMode={darkMode}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
}
