import React, { FC } from 'react';

interface ViewHeaderProps {
  title: string;
  desc?: string;
}

const ViewHeader: FC<ViewHeaderProps> = ({ title, desc }) => (
  <div className="-mt-2">
    <h1 className="prose lg:prose-2xl font-bold text-foreground">{title}</h1>
    <p className="mt-2 text-sm text-foreground">{desc}</p>
  </div>
);

export default ViewHeader;
