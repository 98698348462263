import Organization from './Organization';
import Role from './Role';
import UserActivityLog from './UserActivityLog';
import UserLog from './UserLog'

class User {
    /// <summary>
    /// The user's unique identification number for a user
    /// </summary>
    id: string = '';

    /// <summary>
    /// A 'pointer' to an associated organization's ID
    /// </summary>
    organizationID: string = '';

    /// <summary>
    /// Organization object used for the repository for which the user belongs to
    /// </summary>
    organization: Organization;

    /// <summary>
    /// A string that contains the username of a user
    /// </summary>
    username: string = '';

    /// <summary>
    /// A string that contains the email of the user
    /// </summary>
    email: string = '';

    /// <summary>
    /// A string that contains the first name of user
    /// </summary>
    public firstName: string = '';

    /// <summary>
    /// A string that contains the last name of user
    /// </summary>
    public lastName: string = '';

    /// <summary>
    /// Date for when password was last changed
    /// </summary>
    passwordLastChanged: Date = new Date()

    /// <summary>
    /// A date object that represents when the user was created
    /// </summary>
    created: Date = new Date();

    /// <summary>
    /// A date object that represents when the user was last logged on
    /// </summary>
    lastLogin: Date = new Date();

    /// <summary>
    /// A bool key that shows whether a user is active or inactive
    /// </summary>
    enabled: boolean = false;

    /// <summary>
    /// boolean for if the email verification process has been completed
    /// </summary>
    emailVerified: boolean = false;

    /// <summary>
    /// List of Roles assigned to the User
    /// </summary>
    roles: Role[];

    /// <summary>
    /// boolean for if the email verification process has been completed
    /// </summary>
    activityLogs : UserActivityLog[];
    
    public constructor(json: any) {
        this.id = json.id;
        this.organizationID = json.organizationID;
        this.organization = json.organization;
        this.username = json.username;
        this.email = json.email;
        this.firstName = json.firstName;
        this.lastName = json.lastName;
        this.passwordLastChanged = new Date(json.passwordLastChanged);
        this.created = new Date(json.created);
        this.lastLogin = new Date(json.lastLogin);
        this.enabled = json.enabled;
        this.emailVerified = json.emailVerified; 
        this.roles = json.roles ? json.roles : [];
        this.activityLogs = json.logs ? json.logs : [];        
    };

    public getFullName(): string {
        return this.firstName + ' ' + this.lastName;
    }

}

export default User;