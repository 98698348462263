enum LanguageType {
  English = 1,
}

export default LanguageType;

export function getLanguageTypeString(value: number): string {
  switch (value) {
    case LanguageType.English:
      return 'English';
    default:
      return 'Unknown';
  }
}
