import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { Dialog } from "@mui/material";
import React from "react";

export interface SimpleDialogProps {
  open: boolean;

  onClose: (value: string) => void;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, open } = props;

  const handleClose = () => {};

  const handleListItemClick = (value: string) => {
    onClose(value);
  };

  return (
    <Dialog maxWidth="xs" fullWidth={true} onClose={handleClose} open={open}>
      {/* <UserRolesListView /> */}
    </Dialog>
  );
}

const Home = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <h1>Hello World!</h1>

      <Link to="/Legacy/SourceFeed/List/SourceFeedsListView">
        <Button variant="contained">SourceFeed List</Button>
      </Link>

      <Link to="/Legacy/Users/List/UsersListView">
        <Button variant="contained">User List</Button>
      </Link>

      <Link to="/Legacy/UserProfile/UserProfile">
        <Button variant="contained">User Profile</Button>
      </Link>

      <Link to="/Legacy/login">
        <Button variant="contained">Login</Button>
      </Link>

      <Link to="/Legacy/Navigation/AppShell">
        <Button variant="contained">AppShell</Button>
      </Link>

      <Link to="/Legacy/Navigation/SideBar">
        <Button variant="contained">SideBar</Button>
      </Link>

      <Link to="/Legacy/Navigation/ResponsiveAppBar">
        <Button variant="contained">ResponsiveAppBar</Button>
      </Link>

      <SimpleDialog open={open} onClose={handleClose} />
    </div>
  );
};

export default Home;
