import BaseListView from './BaseListView';
import SourceFeed from '../../../models/SourceFeed';
import UserIcon from '@heroicons/react/24/outline/UserIcon';
import ReactErrorBoundary from '../../Warnings/ReactErrorBoundary';
import SearchBar from '../../SearchBar/SearchBar';
import SourceFeedDetailsDrawer from '../../Drawers/SourceFeedDetailsDrawer/SourceFeedDetailsParentDrawer';
import { useCallback, useState } from 'react';
import CreateNewSourceFeedDialogue from '../../../views/CreateNew/CreateNewSourceFeedDialog';
import User from '../../../models/User';
import UserDetailsParentDrawer from '../../Drawers/UserDetailsDrawer/UserDetailsParentDrawer';

export default function BaseListViewUserParentTest() {
  const [rowSelectedId, setRowSelectedId] = useState<null | string>(null);
  const [isEdited, setIsEdited] = useState(false); //updateBool boolean that is triggered when updateRow()
  // const [createNewSourceFeedTrigger, setCreateNewSourceFeedTrigger] = useState(false);

  const onRowClick = useCallback((id: string) => {
    setRowSelectedId(id);
  }, []);

  const closeDrawer = useCallback(() => {
    setRowSelectedId(null); // Reset the selected row ID to close the drawer
  }, []);

  const updateRow = useCallback(() => {
    setIsEdited((prevIsEdited) => !prevIsEdited);
  }, []);
  console.log('BaseListViewUserParentTest rendered. Row: ' + rowSelectedId);

  return (
    <div>
      <ReactErrorBoundary>
        <BaseListView<User>
          apiURL="/organization/1/user?incDisabled=true"
          title="User"
          desc="A list of all users including their first and last names, usernames, last login dates, emails, and enabled statuses."
          columns={['First Name', 'Last Name', 'Username', 'Email', 'Last Login', 'Enabled']}
          rowData={['firstName', 'lastName', 'username', 'email', 'lastLogin', 'enabled']}
          searchBar={
            <SearchBar
              searchIcon={<UserIcon />}
              searchBarPlaceholder="Search users..."
              //TODO: RN, organization is hardcoded to 1 in API url
              apiURL="/organization/1/user?incDisabled=true"
              searchFields={['firstName', 'lastName', 'username', 'email']}
            />
          }
          onRowSelect={onRowClick}
          onAddButtonClick={() => {}}
          isEdited={isEdited}
        />
        {rowSelectedId !== null && (
          <UserDetailsParentDrawer
            userId={rowSelectedId}
            closeDrawer={closeDrawer}
            updateParentRow={updateRow}
          />
        )}
        {/* {createNewSourceFeedTrigger && <CreateNewSourceFeedDialogue onClose={onDialogClose} />} */}
      </ReactErrorBoundary>
    </div>
  );
}
