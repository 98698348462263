import React, { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import { CircularProgress, ListItem, ListItemText } from '@mui/material';
import Config from '../AppConfig.json';
import User from '../Models/User';
import Organization from '../Models/Organization';
import './UserProfile.styles.css';
import UserActivityLog from '../Models/UserActivityLog';
import UserProfileBrowserView from './UserProfileComponents/UserProfileBrowserView';
import UserProfileMobileView from './UserProfileComponents/UserProfileMobileView';

type UserProfileProps = {
  userID?: string;
};

const format: Intl.DateTimeFormatOptions = {
  month: '2-digit',
  year: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit'

}

const UserProfile = ({ userID = '00000000-faab-0000-fefe-000000480085' }: UserProfileProps) => {
  const [user, setUser] = useState<User>();
  const [open, setOpen] = React.useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    fetch(Config.apiRootURL + `/organization/1/user/${userID}`)
      .then(response => response.json())
      .then(json => {
        const userData = new User(json);

        fetch(Config.apiRootURL + `/organization/${json.organizationID}/user/${json.id}/role`)
          .then(response => response.json())
          .then(jsonRoles => {
            userData.roles = jsonRoles;
          })
          .then(() =>
            fetch(Config.apiRootURL + `/organization/${json.organizationID}?includeUsers=false`)
              .then(response => response.json())
              .then(json3 => {
                userData.organization = json3;
              })
              .then(() =>
                fetch(Config.apiRootURL + `/organization/${userID}/user/${userID}/log/100`)
                  .then(response => response.json())
                  .then(json => {
                    var entries = json.map((item: UserActivityLog) => {
                      return { ...item, timestamp: new Date(item.timestamp) };
                    });
                    userData.activityLogs = entries;
                    setOpen(false);
                    setUser(userData);
                  })
                  .catch(error => {
                    console.error("Error fetching user logs:", error);
                    // Handle the error as needed
                  })
              )
              .catch(error => {
                console.error("Error fetching organization data:", error);
                // Handle the error as needed
              })
          )
          .catch(error => {
            console.error("Error fetching user roles:", error);
            // Handle the error as needed
          });
      })
      .catch(error => {
        console.error("Error fetching user data:", error);
        // Handle the error as needed
      });
  }, [userID]);


  if (open) {

    return (
      <Container maxWidth="md" style={{ marginTop: '50px', textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }
  else if (isSmallScreen && user)
    return (
      <div style={{ position: 'relative', height: '100%' }}>
        <UserProfileMobileView userData={user} />
      </div>
    );

  else if (user) {
    return (
      <UserProfileBrowserView userData={user} />
    );
  }
  else
    return (<><h1>error*</h1></>)
};

export default UserProfile;
