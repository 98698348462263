import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import InitialsAvatar from "./Components/InitialsAvatar";
/*Icons*/
import BadgeIcon from '@mui/icons-material/Badge';
import BlockIcon from '@mui/icons-material/Block';
import LockResetIcon from '@mui/icons-material/LockReset';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import User from '../../Models/User';

type props = {
    user: User;
    handleEditRolesToggle: () => void;
}

const UserProfileTop = ({ user, handleEditRolesToggle }: props) => {

    return (
        <Grid container
            spacing={0}
            direction="row"    
            style={{marginBottom: 20}}                        
        >
            <Grid item xs={4}>
                <Box display="flex" alignItems="center" justifyContent="center" style={{ height: '100%', width: '100%' }}>
                    <InitialsAvatar width={100} height={100} fullName={user.getFullName()} />
                </Box>
            </Grid>
            <Grid item xs={8}>
                <Grid container spacing={1} direction="column" justifyContent="flex-start" >

                    <Grid item xs={4}>
                        <span style={{ fontSize: 28 }}>{user.getFullName()}</span>
                    </Grid>

                    <Grid item xs={4} >
                        <span style={{ fontSize: 14 }}>
                            {user.enabled ? <Chip label="Active" color="success" size="small" variant="outlined" /> : <Chip label="Disabled" color="error" size="small" variant="outlined" />}
                            &nbsp;
                        </span>
                        <span>
                            {user.lastLogin.toLocaleDateString()}
                        </span>


                    </Grid>
                    <Grid item xs={4} >
                        <Button size="small" startIcon={<BadgeIcon/>} onClick={handleEditRolesToggle}>Edit Roles</Button>                     
                        <Button size="small" startIcon={<LockResetIcon />}>Reset Password</Button>
                      
                        <Button size="small" startIcon={<BlockIcon />}>Block sign-in</Button>
                        <Button size="small" startIcon={<PersonRemoveAlt1Icon/>}>Delete user</Button>    
                        
                                          
                    </Grid>
                </Grid>

            </Grid>

        </Grid>

    );
}


export default UserProfileTop;