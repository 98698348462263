import React, { useState, useEffect } from 'react';
import UserProfileDrawerDetails from '../UserProfileDrawerDetails/UserProfileDrawerDetails';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridEventListener, GridRowParams, GridValueGetterParams, MuiEvent } from '@mui/x-data-grid';
import Drawer from '@mui/material/Drawer';
import User from "../../Models/User";
import { SettingsBrightnessSharp } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import { ClickAwayListener, CssBaseline } from '@mui/material';
import internal from 'stream';
import Config from '../../AppConfig.json';

//https://mui.com/material-ui/react-table/#sorting-amp-selecting

interface Data {
  id: string;
  firstName: string;
  lastName: string;
  userName: string;
  email: string,
  dateCreated: string;
  enabled: boolean;
}

function createData(
  id: string,
  firstName: string,
  lastName: string,
  userName: string,
  email: string,
  dateCreated: string,
  enabled: boolean,
): Data {
  return {
    id,
    firstName,
    lastName,
    userName,
    email,
    dateCreated,
    enabled,
  };
}

export interface Organization {
  organizationName: string;
  ownerID: string;
  users: User[];
  licensed: boolean;
  created: Date;
  enabled: boolean;
}

/*
// export interface User {
//   id:                     string;
//   organizationID:         number;
//   username:               string;
//   email:                  string;
//   firstName:              string;
//   lastName:               string;
//   passwordHash?:          string;
//   passwordLastChanged?:   Date;
//   isPasswordTemp:         boolean;
//   created?:               Date;
//   lastLogin?:             Date;
//   enabled:                boolean;
//   passwordFailedAttempts: number;
//   salt?:                  string;
//   otpNotAfter:            Date;
//   emailVerified:          boolean;
// }
// const rows = [
//   createData('1', 'Tobias', 'Simcox', 'Foofding', '6/9/1969', true),
//   createData('2', 'William', 'Simcox', 'Shuca', '6/9/1969', true),
//   createData('3', 'Ruben', 'Taylor', 'CaptDialUp', '6/9/1969', true),
//   createData('4', 'Levi', 'Taylor', 'AlaskaOrigins', '6/9/1969', true),
//   createData('5', 'John', 'Smith', 'JMan', '6/9/1969', true),
//   createData('6', 'Rupert', 'Schnizzelfist', 'Rupertnator', '6/9/1969', true),
//   createData('7', 'Jane', 'Loveburger', 'BorgerGirl', '6/9/1969', true),
//   createData('8', 'Ape', 'Muscleman', 'Monkee', '6/9/1969', true),
//   createData('9', 'Gordy', 'Teetman', 'GordLord', '6/9/1969', true),
//   createData('10', 'Harper', 'Fartsmith', 'Artsy Fartsy', '6/9/1969', true),
// ];
*/

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90, hide: true},
  {
    field: 'firstName',
    headerName: 'First name',
    width: 200,

  },
  {
    field: 'lastName',
    headerName: 'Last name',
    width: 200,

  },
  {
    field: 'userName',
    headerName: 'Username',
    type: 'string',
    width: 240,

  },
  {
    field: 'email',
    headerName: 'Email',
    type: 'string',
    width: 300,

  },
  {
    field: 'dateCreated',
    headerName: 'Date Created',
    type: 'string',
    width: 150,

  },
  {
    field: 'enabled',
    headerName: 'Enabled',
    type: 'boolean',
    width: 100,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      if (params.row.enabled) return (<Chip label="Active" color="success" size="small" variant="outlined" />);
      return <Chip label="Disabled" color="error" size="small" variant="outlined" />;
    }

  }
];

var suppressOnce:boolean = false;

const UsersListView = () => {
 
  const [drawervisibility, setDrawerVisibility] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string>();
  const [rows, setRows] = useState<Data[]>([]);
  const [open, setOpen] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [firstTimePageLoad, setFirstTimePageLoad] = React.useState(true);
  let offset = (page * pageSize); 

  function populateListView(orgId: number, incDisabled: boolean = false, offset: number, limit: number) {

    fetch(`${Config.apiRootURL}/organization/${orgId}/user?incDisabled=${incDisabled}&offset=${offset}&limit=${limit}`)
      .then(response => response.json())
      .then(json => {
        const userData: User[] = json.results;
        const dataSet = userData.map(p => createData(p.id, p.firstName, p.lastName, p.username, p.email, new Date(p.created).toLocaleDateString(), p.enabled));        

        if (firstTimePageLoad) {
          setTotalRecordCount(json.totalRecordCount);
          setFirstTimePageLoad(false);         
        }

        if (open == true) setOpen(false);
        setRows(dataSet);

      });
  };


  function handlePageChange(newPageChange: number) {     
    let totalPages = Math.ceil(totalRecordCount / pageSize);
    
    if (suppressOnce){   
      suppressOnce = false;
      return;
    }
   
    if (newPageChange <= totalPages)
      setPage(newPageChange);
    else return;
  }

  function handlePageSizeChange(newPageSize: number) {
    let totalPages = Math.ceil(totalRecordCount / pageSize);   
    if ((page+1) >= totalPages) suppressOnce = true;
    setPageSize(newPageSize); 
    setPage(0);
  }

  const handleClickAway = () => {   
    setDrawerVisibility(false);
  };

  useEffect(() => {    
    populateListView(1, true, offset, pageSize);   
  }, [pageSize, page]);


  if (open == true) return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>);

  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <DataGrid
      autoHeight 
       sx={{
        '.MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold !important',
          justifyContent: 'left',   
          fontSize: '1rem'      
        },
        '.MuiDataGrid-columnHeader:focus-within': {
          outline: 'none !important'
        },
        '.MuiDataGrid-cell:focus-within': {
          outline: 'none !important'
      },
      }}
        rows={rows}
        columns={columns}
        paginationMode="server"
        rowCount={totalRecordCount}
        page={page}
        pageSize={pageSize}
        onPageChange={(newPageChange) => handlePageChange(newPageChange)}
        onPageSizeChange={(newPageSize, event) => { handlePageSizeChange(newPageSize); }}
        
        rowsPerPageOptions={[10, 20, 50, 100]}    
        isCellEditable={(param) => false}
        disableSelectionOnClick
        onRowClick={(params, event) => {         
          setSelectedUser(params.row.id);
          setDrawerVisibility(true);          
        }}
      />
      <Drawer       
        style={{padding: 150 }}
        anchor="right"
        open={drawervisibility}
        ModalProps={{ onBackdropClick: handleClickAway }}
      >
        <UserProfileDrawerDetails closeWindow={() => setDrawerVisibility(false)} userID={selectedUser} />
      </Drawer>      
    </Box>
  );
}

export default UsersListView;