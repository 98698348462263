import AppBar from '@mui/material/AppBar';
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from "react";
import Config from '../../AppConfig.json';
import "./SourceFeedDetails.styles.css";
import SourceFeed from '../../Models/SourceFeed';
import FormatType, { getFormatTypeString } from '../FormatType';

/* icons */
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import Backdrop from "@mui/material/Backdrop";
import EditIcon from '@mui/icons-material/Edit';
import { Box, Chip, CircularProgress, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';


type SourceFeedDetailsProps = {
    closeWindow?: () => void;
    sourceFeedID?: string;
}

let emptyFeed: SourceFeed = new SourceFeed([]);

const SourceFeedDetails = ({ closeWindow, sourceFeedID }: SourceFeedDetailsProps) => {

    const [sourceFeed, setSourceFeed] = useState<SourceFeed>(emptyFeed);
    const [open, setOpen] = React.useState(true);
    const [refreshData, setRefreshData] = useState(false);
    const [edit, setEdit] = useState(false);
    const [editRolesToggle, setEditRolesToggle] = useState(false);

    useEffect(() => {
        fetch(Config.apiRootURL + "/sourcefeed/" + sourceFeedID + "?categories=true")        
            .then(response => response.json())
            .then(json1 => {
                const feed: SourceFeed = json1;
                feed.location = "TODO: location";
                feed.authorID = json1.authorID; // Extract authorID from the first fetch response
                feed.formatTypeID = getFormatTypeString(json1.formatTypeID);
                feed.dateCreated = new Date(json1.dateCreated);
                feed.lastSyncTime = new Date(json1.lastSyncTime);
                feed.lastUpdated = new Date(json1.lastUpdated);

                //fetch the username of the author after extracting authorID from sourceFeed
                fetch(Config.apiRootURL + "/organization/" + feed.organizationID + "/user/" + feed.authorID)                
                    .then(response => response.json())
                    .then(json2 => {
                        feed.author = json2.username;
                        feed.organizationID = json2.organizationID;

                        //fetch the name of the organization the sourcefeed was created under by organizationID
                        fetch(Config.apiRootURL + "/organization/" + feed.organizationID + "?includeUsers=false")                        
                            .then(response => response.json())
                            .then(json3 => {
                                setSourceFeed(({ ...feed, organizationName: json3.organizationName }));
                                setOpen(false);
                            })
                            .catch(error => {
                                console.error('Error fetching feed\'s organization:', error);
                                feed.organizationName = "Fetch Error: Org"
                                setOpen(false);
                            });
                    })
                    .catch(error => {
                        console.error('Error fetching author details:', error);
                        feed.author = "Fetch Error: Author"
                        setOpen(false);
                    });
            })
            .catch(error => {
                console.error('Error fetching source feed:', error);
                setOpen(false);
            });



    }, [refreshData]);


    function setRefreshDataFunc(refreshData: boolean) {
        setRefreshData(refreshData);
    }


    function handleEditRolesToggle() {
        if (!editRolesToggle)
            setEditRolesToggle(true)
        else setEditRolesToggle(false)
    }



    if (open == true) return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>);
    return (
        <div style={{ position: "relative", height: "100%" }}>
            <div>
                <span style={{ display: "flex", justifyContent: "right" }}>
                    <IconButton onClick={() => setEdit(!edit)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => setRefreshDataFunc(!refreshData)}>
                        <RefreshIcon />
                    </IconButton>
                    <IconButton onClick={closeWindow}>
                        <CloseIcon />
                    </IconButton>
                </span>
            </div>

            <Grid container
                spacing={0}
                direction="column"
                paddingLeft="30px"
                paddingRight="30px"

            >
                <Grid item xs={3} >
                    <span style={{ fontSize: 14, width: '100%' }}>
                        {sourceFeed.enabled ? <Chip label="Active" color="success" size="small" variant="outlined" style={{ width: '100%' }} /> : <Chip label="Disabled" color="error" size="small" variant="outlined" style={{ width: '100%' }} />}
                        &nbsp;
                    </span>
                    <div className="subjectHeader">
                        <h4>Name</h4>
                        <p className="sourceFeedName">{sourceFeed.name}</p>
                    </div>
                    <div className="subjectHeader">
                        <h4>Source Domain</h4>
                        <a href={sourceFeed.sourceDomain}>{sourceFeed.sourceDomain}</a>
                    </div>
                    <div className="subjectHeader">
                        <h4>Location URL</h4>
                        <a href={sourceFeed.locationURL}>{sourceFeed.locationURL}</a>
                    </div>
                </Grid>
                <Grid item xs={2} >
                    <Grid container
                        direction="row"
                        paddingTop="20px"
                    >
                        <Grid item xs={6} >
                            <div className="subjectHeader">
                                <h4>Author</h4>
                                {sourceFeed.author || "Username"}
                            </div>
                            <div className="subjectHeader">
                                <h4>Date Created</h4>
                                {sourceFeed.dateCreated.toLocaleString()}
                            </div>
                        </Grid>
                        <Grid item xs={6} >
                            <div className="subjectHeader">
                                <h4>Organization</h4>
                                {sourceFeed.organizationName}
                            </div>

                            <div className="subjectHeader">
                                <h4>Updated</h4>
                                {sourceFeed.lastUpdated.toLocaleString()}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3} >
                    <div className="subjectHeader">
                        <h4>Categories</h4>

                        <Box height='auto' maxHeight={160} width={120} paddingBottom='10px'>

                            <ul className='categoriesList'>
                                {sourceFeed.categories.map((cat, index) => {
                                    return (
                                        <li key={index}>• {cat.name}</li>
                                    );
                                })}
                            </ul>


                        </Box>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <Grid container
                        direction="row">
                        <Grid item xs={6} >
                            <div className="subjectHeader">
                                <h4>Format Type</h4>
                                {sourceFeed.formatTypeID + " " + sourceFeed.formatVersion}
                            </div>
                            <div className="subjectHeader">
                                <h4>Region Type ID</h4>
                                {sourceFeed.regionTypeID}
                            </div>
                            <div className="subjectHeader">
                                <h4>Last Sync Time</h4>
                                {sourceFeed.lastSyncTime.toLocaleString()}
                            </div>
                            <div className="subjectHeader">
                                <h4>Crawl Window Min</h4>
                                {sourceFeed.crawlWindowMax
                                    ? sourceFeed.crawlWindowMax.toLocaleTimeString()
                                    : "NA"}
                            </div>
                        </Grid>
                        <Grid item xs={6} >
                            <div className="subjectHeader">
                                <h4>Language ID</h4>
                                {sourceFeed.languageCodeID}
                            </div>
                            <div className="subjectHeader">
                                <h4>Location</h4>
                                {sourceFeed.location}
                            </div>
                            <div className="subjectHeader">
                                <h4>Crawl Interval</h4>
                                {sourceFeed.crawlInterval}
                            </div>
                            <div className="subjectHeader">
                                <h4>Crawl Window Max</h4>
                                {sourceFeed.crawlWindowMax
                                    ? sourceFeed.crawlWindowMax.toLocaleTimeString()
                                    : "NA"}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

{/* <AppBar position="absolute" color="inherit" sx={{ top: 'auto', bottom: 0 }}>
<Toolbar style={{ display: "flex", justifyContent: "right" }}>
    <span>
        <Button onClick={closeWindow} variant="text">Cancel</Button>
        <Button variant="text">Save Changes</Button>
    </span>
</Toolbar>
</AppBar> */}

export default SourceFeedDetails;