import React, { FC, useEffect } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

const navigation = [
  { name: 'Account', href: '/settings/account', current: true },
  { name: 'Notifications', href: '/settings/notifications', current: false },
  { name: 'Billing', href: '#', current: false },
  { name: 'Teams', href: '#', current: false },
  { name: 'Integrations', href: '#', current: false },
  { name: 'Security', href: '/settings/security', current: false },
];

export default function Settings() {
  const location = useLocation();
  const navigate = useNavigate();

  // Redirect from /settings and /settings/ to /settings/account
  useEffect(() => {
    if (location.pathname === '/settings' || location.pathname === '/settings/') {
      navigate('account');
    }
  }, [location, navigate]);

  // Update the current property of the navigation array based on the current pathname
  const updatedNavigation = navigation.map((item) => ({
    ...item,
    current: item.href === location.pathname,
  }));

  return (
    <div>
      <header className="border-b border-foreground/10 mb-16">
        {/* navigation */}
        <nav className="flex overflow-x-auto -py-4">
          <ul
            role="list"
            className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-foreground sm:px-6 lg:px-8"
          >
            {updatedNavigation.map((item) => (
              <li key={item.name}>
                <Link
                  to={item.href}
                  className={item.current ? 'text-foreground' : 'text-foreground/50'}
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </header>

      <Outlet />
    </div>
  );
}
