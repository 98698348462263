import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import MenuIcon from "@mui/icons-material/Menu";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Copyright } from "../LegacyApp";

const theme = createTheme({
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
  },
  palette: {
    primary: {
      main: "#EFEFEF",
    },
  },
});

interface SideBarProps {
  open: boolean;
  onCollapse: () => void;
  onExpand: () => void;
}

const navigationItems = [
  { icon: <HomeIcon />, text: "Home", path: "/" },
  { icon: <PeopleIcon />, text: "Users", path: "/Users/List/UsersListView" },
  {
    icon: <AccountBoxIcon />,
    text: "Roles",
    path: "/Organization/RolesListView",
  },
  {
    icon: <RssFeedIcon />,
    text: "Feeds",
    path: "/SourceFeed/List/SourceFeedsListView",
  },
];

const fadeInClass = {
  opacity: 1,
  transition: "opacity 200ms ease-in-out",
};

const SideBar: React.FC<SideBarProps> = ({ open, onCollapse, onExpand }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showCopyright, setShowCopyright] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    if (isExpanded) {
      onCollapse();
    } else {
      onExpand();
    }
    setIsExpanded(!isExpanded);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  useEffect(() => {
    if (isExpanded) {
      const timer = setTimeout(() => {
        setShowCopyright(true);
      }, theme.transitions.duration.enteringScreen); // Delay should match the transition duration
      return () => clearTimeout(timer);
    } else {
      setShowCopyright(false);
    }
  }, [isExpanded, theme.transitions.duration.enteringScreen]);

  return (
    <ThemeProvider theme={theme}>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          width: isExpanded ? theme.spacing(20) : theme.spacing(7),
          "& .MuiDrawer-paper": {
            width: isExpanded ? theme.spacing(20) : theme.spacing(7),
            paddingTop: "57px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            overflowX: "hidden",
            backgroundColor: "white",
            boxShadow: theme.shadows[4],
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          },
        }}
      >
        <List>
          {/* Toggle Button */}
          <ListItemButton
            onClick={handleDrawerToggle}
            sx={{
              justifyContent: "center", // Center the toggle button horizontally
              ".MuiListItemIcon-root": { justifyContent: "center" }, // Center the icon inside the ListItemIcon
            }}
          >
            <ListItemIcon sx={{ color: "#123550" }}>
              <MenuIcon sx={{ fontSize: 32 }} />
            </ListItemIcon>
            {isExpanded && <ListItemText primary="Menu" />}
          </ListItemButton>
          {/* Navigation Items */}
          {navigationItems.map((item) => (
            <ListItemButton
              key={item.text}
              onClick={() => handleNavigation(item.path)}
              sx={{
                justifyContent: "center", // Center the ListItemButton content horizontally
                ".MuiListItemIcon-root": { justifyContent: "center" }, // Center the icon inside the ListItemIcon
              }}
            >
              <ListItemIcon sx={{ color: "#123550", display: "flex" }}>
                {React.cloneElement(item.icon, { sx: { fontSize: 32 } })}
              </ListItemIcon>
              {isExpanded && <ListItemText primary={item.text} />}
            </ListItemButton>
          ))}
        </List>
        {/* Conditional rendering based on delayed state */}
        {isExpanded && (
          <Box
            sx={{
              p: 2,
              textAlign: "center",
              mt: "auto",
              opacity: 0, // Start with 0 opacity
              ...(showCopyright && fadeInClass), // Apply the fade-in class when copyright should show
            }}
          >
            <Copyright sx={{ fontSize: "0.5rem" }} />
          </Box>
        )}
      </Drawer>
    </ThemeProvider>
  );
};

export default SideBar;
