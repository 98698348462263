import { KeyIcon } from '@heroicons/react/24/outline';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import Config from '../../../app-config.json';
import MultiselectList from '../../Controls/MultiselectList/MultiselectList';
import User from '../../../models/User';
import { Controller, useFormContext } from 'react-hook-form';
import Role from '../../../models/Role';
import LoadingSpinner from '../../Controls/LoadingIcons/LoadingSpinner';

let emptyUser: User = new User([]);

type Props = {
  refreshData: boolean;
  userId: string;
  setPendingChanges: () => void;
};

export default function UserDetailsEditDrawer({ refreshData, userId, setPendingChanges }: Props) {
  const [dbOrgRoles, setDbOrgRoles] = useState<Role[]>([]);
  const [user, setUser] = useState<User>(emptyUser);
  const [loading, setLoading] = useState(true);
  const [assignedRoles, setAssignedRoles] = useState<Role[]>([]);
  const [pendingUserChange, setPendingUserChange] = useState(false);
  const {
    watch,
    register,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<User>();
  const enabled = watch('enabled');

  //TODO: for when we want to enable editing of User's name, username, email, etc.
  let disabledInputs: boolean = false;

  //Triggers first API calls
  useEffect(() => {
    setLoading(true);
    //TODO: organization ID hardcoded for now to 1. Need to make dynamic later
    getUserById(1, userId);
    return () => {
      reset();
    };
  }, [refreshData, userId]); // Trigger effect when 'open' state changes

  //API calls to get User data, assigned roles as well as roles from user's organization to later select and assign to User.
  function getUserById(orgId: number, userId: string) {
    reset();
    fetch(`${Config.apiRootURL}/organization/${orgId}/user/${userId}`)
      .then((response) => response.json())
      .then((json1) => {
        let tempUser: User = new User(json1);

        // Set the form values with fetched user data

        fetch(`${Config.apiRootURL}/organization/${orgId}/user/${userId}/role`)
          .then((response) => response.json())
          .then((json2) => {
            tempUser.roles = json2;

            fetch(`${Config.apiRootURL}/role/org/${orgId}`)
              .then((response) => response.json())
              .then((json3) => {
                reset(tempUser);
                setDbOrgRoles(json3);
                setAssignedRoles(tempUser.roles);
                setLoading(false);
              })
              .catch((error) => {
                console.error('Error fetching organization roles:', error);
              });
          })
          .catch((error) => {
            console.error("Error fetching user's roles:", error);
          });
      })
      .catch((error) => {
        console.error('Error fetching user:', error);
      });
  }
  const handleInputChange = () => {
    setPendingChanges();
  };

  //Handler function to toggle and track Active/Disabled status of user in Edit mode
  const handleToggle = (status: boolean) => {
    setValue('enabled', status);
    setPendingChanges();
  };

  //Handler function to update selected roles of User.
  const handleSelectionChange = (updatedSelection: Role[]) => {
    setValue('roles', updatedSelection, { shouldValidate: true });

    //After updatedSelection is is updated it is assigned to assignedCategores, and pendingChanges changes to true;
    setAssignedRoles(() => {
      setPendingChanges();
      return updatedSelection;
    });
  };

  return (
    <div className="h-full flex flex-col">
      <div className="flex-grow px-4 sm:gap-4 sm:px-0">
        <div className="pt-4 pb-8">
          {/* START - SourceFeed details content */}
          <div className="flex justify-between">
            <div>
              <label className="block text-lg sm:text-md font-bold pt-2 leading-6 text-foreground">Password</label>
              <button className="text-foreground drop-shadow-sm sm:w-42 w-26 bg-secondary h-8 text-md font-base mr-1 my-1 rounded-md ring-1 ring-tertiary flex items-center justify-between space-x-1 px-3 mt-1.5 bg-background/30 ring-inset hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-inset">
                <span className="sm:text-base text-sm flex items-center  ">
                  <span className="p-1">Reset</span>
                  <KeyIcon className="sm:h-5 sm:w-5 h-4 w-6 text-primary m-0" />
                </span>
              </button>
            </div>
            <span>
              <label className="block text-lg sm:text-md font-bold pt-2 leading-6 text-foreground">
                Account Status
              </label>
              <button
                disabled={loading}
                type="button"
                onClick={() => handleToggle(true)}
                className={`mr-2 my-2 inline-flex items-center rounded-full bg-transparent px-2 py-1 sm:text-base text-sm font-medium ${
                  enabled && !loading
                    ? 'text-green-600 ring-1 ring-inset ring-green-600'
                    : 'text-gray-400 ring-1 ring-inset ring-gray-400/70 hover:ring-green-600/40 hover:text-green-600/40'
                }`}
              >
                Active
              </button>
              <button
                disabled={loading}
                type="button"
                onClick={() => handleToggle(false)}
                className={`mr-1 my-2 inline-flex items-center rounded-full bg-transparent px-2 py-1 sm:text-base text-sm font-medium ${
                  !enabled && !loading
                    ? 'text-red-600 ring-1 ring-inset ring-red-600 '
                    : 'text-gray-400 ring-1 ring-inset ring-gray-400/70 hover:ring-red-600/40 hover:text-red-600/40'
                }`}
              >
                Disabled
              </button>
            </span>
            <input
              type="hidden"
              {...register('enabled')}
            />
          </div>
        </div>
        {/* end of top */}
        <div className="py-8 border-t border-tertiary">
          <div className="flex pb-0 mb-4">
            <div className="w-full mr-1">
              <label className="block text-lg sm:text-md font-bold pt-2 leading-6 text-foreground">First Name</label>
              <input
                disabled={disabledInputs}
                {...register('firstName', {
                  minLength: { value: 1, message: 'Must be a min length of 1 characters.' },
                  maxLength: { value: 32, message: 'Must be under 32 characters.' },
                })}
                onChange={handleInputChange}
                className={`mr-1 w-full block rounded-md border p-1 mt-1.5 bg-background/50 text-foreground shadow-sm ring-1 ring-secondary placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-tertiary disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 text-base sm:leading-6 ${
                  errors.firstName ? 'border-red-500' : 'border-0'
                }${loading ? ' animate-pulse' : ''}`}
                placeholder={loading ? '' : user.firstName}
              />
              <p className="text-alert pt-1 text-sm">{errors.firstName?.message}</p>
            </div>
            <div className="w-full ml-1">
              <label className="block text-lg sm:text-md font-bold pt-2 leading-6 text-foreground">Last Name</label>
              <input
                disabled={disabledInputs}
                {...register('lastName', {
                  minLength: { value: 1, message: 'Must be a min length of 1 characters.' },
                  maxLength: { value: 32, message: 'Must be under 32 characters.' },
                })}
                onChange={handleInputChange}
                className={`ml-1 block w-full rounded-md border p-1 mt-1.5 bg-background/50 text-foreground shadow-sm ring-1 ring-secondary placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-tertiary disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 text-base sm:leading-6 ${
                  errors.lastName ? 'border-red-500' : 'border-0'
                }${loading ? ' animate-pulse' : ''}`}
                placeholder={loading ? '' : user.lastName}
              />
              <p className="text-alert pt-1 text-sm">{errors.lastName?.message}</p>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-lg sm:text-md font-bold pt-2 leading-6 text-foreground">Email</label>
            <input
              disabled={disabledInputs}
              {...register('email', {
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: 'Invalid email address. Please enter a valid email.',
                },
              })}
              onChange={handleInputChange}
              className={`block w-full rounded-md border p-1 mt-1.5 bg-background/50 text-foreground shadow-sm ring-1 ring-secondary placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-tertiary disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 text-base sm:leading-6 ${
                errors.email ? 'border-red-500' : 'border-0'
              }${loading ? ' animate-pulse' : ''}`}
              placeholder={loading ? '' : user.email}
            />
            <p className="text-alert pt-1 text-sm">{errors.email?.message}</p>
          </div>
          <div className="mb-4">
            <label className="block text-lg font-bold pt-2 leading-6 text-foreground">Username</label>
            <input
              disabled={disabledInputs}
              {...register('username', {
                minLength: { value: 3, message: 'Must be a min length of 3 characters.' },
                maxLength: { value: 32, message: 'Must be under 32 characters.' },
              })}
              onChange={handleInputChange}
              className={`block w-full rounded-md border p-1 mt-1.5 bg-background/50 text-foreground shadow-sm ring-1 ring-secondary placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-tertiary disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 text-base sm:leading-6 ${
                errors.username ? 'border-red-500' : 'border-0'
              }${loading ? ' animate-pulse' : ''}`}
              placeholder={loading ? '' : user.username}
            />
            <p className="text-alert pt-1 text-sm">{errors.username?.message}</p>
          </div>
        </div>

        <div className="pt-8 pb-12 border-t border-tertiary">
          <div className="block text-lg sm:text-md pt-2 leading-6 text-foreground">
            <label className="font-bold">User Roles</label>
            <div className="">
              {' '}
              {loading ? (
                <div className="flex animate-bounce items-center justify-center ">
                  <LoadingSpinner size="h-8" />
                </div>
              ) : (
                <div>
                  {' '}
                  <Controller
                    name="roles"
                    control={control}
                    render={({ field }) => (
                      <MultiselectList
                        dbList={dbOrgRoles}
                        preAssignedList={assignedRoles}
                        onSelectionChange={handleSelectionChange}
                        errorInSelection={errors.roles ? true : false}
                      />
                    )}
                  />
                  {errors.roles && <p className="text-red-500 text-sm">{errors.roles.message}</p>}
                </div>
              )}{' '}
            </div>
          </div>
        </div>
      </div>
      <div className="py-8 border-t border-tertiary h-full flex flex-col">
        <div className="flex-grow"></div>
        <button className="hover:bg-alert/70 text-shell font-semibold bg-alert sm:text-md rounded-md ring-1 ring-tertiary w-full px-3 py-1.5 mt-1.5 ring-inset hover:bg-alert- focus:outline-none focus:ring-1 focus:ring-inset flex items-center justify-center">
          <span className="text-base font-semibold">Delete user</span>
        </button>
      </div>
    </div>
  );
}
