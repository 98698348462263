// useDarkModeStore.ts
import { create } from "zustand";

// Function to get the initial darkMode state
const getInitialDarkMode = () => {
	const localStorageValue = localStorage.getItem("darkMode");
	if (localStorageValue !== null) {
		return localStorageValue === "true";
	} else {
		return window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
	}
};

// Create a Zustand store with a darkMode state and a setDarkMode action
const useDarkModeStore = create(set => ({
	// The darkMode state is initially set using the getInitialDarkMode function
	darkMode: getInitialDarkMode(),

	// The setDarkMode action updates the darkMode state and saves it to localStorage
	setDarkMode: (darkMode: boolean) => {
		localStorage.setItem("darkMode", String(darkMode));
		set({ darkMode });

		// Set data-theme to "dark" if darkMode is true, otherwise remove the attribute
		if (darkMode) {
			document.documentElement.setAttribute("data-theme", "dark");
		} else {
			document.documentElement.setAttribute("data-theme", "light");
		}
	},
}));

export default useDarkModeStore;
