import React from 'react';

const LoadingSpinner: React.FC<{ size?: string; color?: string }> = ({ size = 'h-8', color = 'text-primary' }) => (
  <svg
    className={`animate-spin ${size} ${color}`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 100 100" // Adjusted viewBox for better representation
    style={{ width: size, height: size }} // Dynamically adjust width and height based on size prop
  >
    <circle
      className="opacity-25"
      cx="50"
      cy="50"
      r="40" // Adjusted radius to match viewBox
      stroke="currentColor"
      strokeWidth="10" // Increased stroke width for better visibility
    ></circle>
    <path
      d="M50 10 A 40 40 0 0 1 90 50"
      fill="transparent" // Made the path transparent
      stroke="currentColor"
      strokeWidth="10" // Same stroke width as the circle
    />
  </svg>
);

export default LoadingSpinner;
