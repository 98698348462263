import {
  AppBar,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Tooltip,
} from '@mui/material';
import Config from '../../AppConfig.json';
import React, { useEffect, useState } from 'react';
import './UserRolesListViewEditable.styles.css';
import User from '../../Models/User';
import Role from '../../Models/Role';

type props = {
  user: User;
};

let initialized: boolean = false;

export default function UserRolesListEditableView({ user }: props) {
  const [open, setOpen] = React.useState(true);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [orgRolesList, setOrgRolesList] = useState<Role[]>([]);

  //list of roles that is passed with the user object from parent component
  const [userRolesIDList, setUserRolesIDList] = useState<number[]>(() => user.roles.map((role) => role.id));
  const userRolesOnFirstLoad: number[] = user.roles.map((role) => role.id);

  //component loads, the user-object's roles are compared with the user's organization's list of roles.
  useEffect(() => {
    if (!initialized) pullOrgRoles(user);
    return function cleanup() {
      initialized = false;
    };
  }, []);
  //Whenever checkbox is clicked, changePending() fires to check to see if there are any changes pending, thus enabling or disabling save button.
  useEffect(() => {
    changePending();
  }, [userRolesIDList]);

  //fetches user's org's roles to be added to checkbox list displayed in component.
  function pullOrgRoles(user?: User) {
    if (!user) {
      console.log('##USER NOT PROVIDED!!');
      return;
    } else {
      fetch(`${Config.apiRootURL}/role/org/${user.organizationID}`)
        .then((response) => response.json())
        .then((json) => {
          setOrgRolesList(json);
        });
    }
    initialized = true;
  }
  //Onclick handle for when checkbox is clicked
  function handleOnClick(roleId: number) {
    rolesListsCompareAndAdd(roleId);
    changePending();
  }
  //when cancel button is clicked, the list of checkbox roles reverts back to what the user's roles were upon first page load.
  function handleCancelButtonClick() {
    setUserRolesIDList(userRolesOnFirstLoad);
  }

  //When the save button is clicked it will shoot an API call and then become disabled.
  function handleSaveButtonClick() {
    fetch(`${Config.apiRootURL}/organization/${user.organizationID}/user/${user.id}/roles`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userRolesIDList),
    });
    setDisableSaveButton(true);
  }

  //when a checkbox from the roles list is clicked by user, this method is called in order to add or remove the clicked role from the user's list of roles.
  function rolesListsCompareAndAdd(roleId: number) {
    if (!userRolesIDList.includes(roleId)) {
      setUserRolesIDList((initArray) => [...initArray, roleId]);
    } else {
      setUserRolesIDList((initArray) => initArray.filter((p) => p !== roleId));
    }
  }

  //Method that determins if the save button is disabled or enabled.
  //If changes are pending then the save button is enabled, if not then it is disabled.
  function changePending() {
    let trigger = false;
    userRolesOnFirstLoad.forEach((p) => {
      if (!userRolesIDList.includes(p)) {
        trigger = true;
        return;
      }
    });
    userRolesIDList.forEach((p) => {
      if (!userRolesOnFirstLoad.includes(p)) {
        trigger = true;
        return;
      }
    });

    if (trigger) {
      setDisableSaveButton(false);
    } else {
      setDisableSaveButton(true);
    }
  }

  //Component:
  return (
    <Box sx={{ height: 'auto', width: 'auto' }}>
      {!user && (
        <div>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}

      <List sx={{ height: '100%', bgcolor: 'background.paper', overflow: 'auto' }}>
        {/*the list of user's org's roles are mapped through and displayed in component. 
        the boxes are checked depending on whether or not the user is assigned to said roles*/}
        {orgRolesList?.length > 0 &&
          orgRolesList.map((role, index) => {
            const labelId = `checkbox-list-label-${role.id}`;
            const tooltipKey = `tooltip-${role.id}-${index}`;
            if (userRolesIDList.includes(role.id)) {
              return (
                <Tooltip
                  title={role.description}
                  placement="left"
                  key={tooltipKey}
                >
                  <ListItem
                    key={index}
                    disablePadding
                  >
                    <Checkbox
                      checked={true}
                      onClick={() => handleOnClick(role.id)}
                      edge="start"
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                    <ListItemText
                      style={{ paddingLeft: 6 }}
                      id={labelId}
                      primary={role.name}
                    />
                  </ListItem>
                </Tooltip>
              );
            } else
              return (
                <Tooltip
                  title={role.description}
                  placement="left"
                  key={tooltipKey}
                >
                  <ListItem
                    key={index}
                    disablePadding
                  >
                    <Checkbox
                      checked={false}
                      onClick={() => handleOnClick(role.id)}
                      edge="start"
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                    <ListItemText
                      style={{ paddingLeft: 6 }}
                      id={labelId}
                      primary={role.name}
                    />
                  </ListItem>
                </Tooltip>
              );
          })}
      </List>
      <AppBar
        position="absolute"
        color="inherit"
        sx={{ top: 'auto', bottom: 0 }}
      >
        <Toolbar style={{ display: 'flex', justifyContent: 'right' }}>
          <span>
            <Button
              variant="text"
              size="large"
              onClick={() => handleCancelButtonClick()}
            >
              Cancel
            </Button>
            <Button
              variant="text"
              size="large"
              disabled={disableSaveButton}
              onClick={() => handleSaveButtonClick()}
            >
              Save Changes
            </Button>
          </span>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
