enum RegionType {
  Universal = 1,
  Global = 2,
  National = 3,
  Regional = 4,
  State = 5,
  County = 6,
  City = 7,
  Hyperlocal = 8,
}

export default RegionType;

export function getRegionTypeString(value: number): string {
  switch (value) {
    case RegionType.Universal:
      return 'Universal';
    case RegionType.Global:
      return 'Global';
    case RegionType.National:
      return 'National';
    case RegionType.Regional:
      return 'Regional';
    case RegionType.County:
      return 'County';
    case RegionType.City:
      return 'City';
    case RegionType.Hyperlocal:
      return 'Hyperlocal';
    default:
      return 'Unknown';
  }
}
