import React, { FC } from 'react';
import Particles from '../../components/Other/Particles';

interface RegistrationProps {}

const Registration: FC<RegistrationProps> = () => (
  <div className="flex min-h-screen items-center justify-center bg-shell md:bg-background py-12 px-4 sm:px-6 lg:px-8">
    <Particles className="absolute inset-0 z-0" />

    <div className="w-full max-w-lg space-y-8 bg-transparent p-0 md:bg-shell md:p-10 rounded-xl drop-shadow-xl">
      <div>
        <div className="relative w-24 h-24 mx-auto mb-4">
          <div className="absolute inset-0 bg-foreground rounded-full block shadow-md"></div>
          <img
            alt="Your Company"
            src="https://icebreaker.blacktine.com/tempLogo.png"
            className="relative mx-auto h-24 w-auto"
          />
        </div>
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-foreground">Register your account</h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-background px-6 py-12 shadow rounded-lg sm:px-12">
          <form
            action="#"
            method="POST"
            className="space-y-6"
          >
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-md font-medium leading-6 text-foreground"
                >
                  First Name
                </label>
                <div className="mt-2">
                  <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    required
                    className="block w-full rounded-md border-0 px-2 py-1.5 bg-shell text-foreground shadow-sm placeholder:text-foreground/75 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="lastName"
                  className="block text-md font-medium leading-6 text-foreground"
                >
                  Last Name
                </label>
                <div className="mt-2">
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    required
                    className="block w-full rounded-md border-0 px-2 py-1.5 bg-shell text-foreground shadow-sm placeholder:text-foreground/75 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-md font-medium leading-6 text-foreground"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  autoComplete="email"
                  className="block w-full rounded-md border-0 px-2 -px-3 py-1.5 bg-shell text-foreground shadow-sm placeholder:text-foreground/75 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-md font-medium leading-6 text-foreground"
              >
                Password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  autoComplete="current-password"
                  className="block w-full rounded-md border-0 px-2 -px-3 py-1.5 bg-shell text-foreground shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="confirmPassword"
                className="block text-md font-medium leading-6 text-foreground"
              >
                Confirm Password
              </label>
              <div className="mt-2">
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  required
                  className="block w-full rounded-md border-0 px-2 py-1.5 bg-shell text-foreground shadow-sm placeholder:text-foreground/75 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded text-primary focus:text-primary accent-primary"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-3 block text-sm leading-6 text-foreground"
                >
                  Agree to Terms and Conditions
                </label>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 lg:text-md font-semibold leading-6 text-white shadow-sm hover:bg-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 active:scale-105 transition-transform duration-75"
              >
                Register
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
);

export default Registration;
