import { Fragment, useState, FC, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { XCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { Message } from '@mui/icons-material';
import { FallbackProps} from 'react-error-boundary';
import React from 'react';

interface IError {
  error: Error;
}

const PopError: FC<IError> = ({ error }) => {
  const [show, setShow] = useState(true);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false); // Hide the popup after 10 seconds
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Trigger fade-out animation when show state changes to false
    if (!show) {
      const fadeOutTimer = setTimeout(() => {
        setIsVisible(false);
      }, 500); // Adjust the duration as needed for the fade-out animation
      return () => clearTimeout(fadeOutTimer);
    }
  }, [show]);

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        {isVisible && (
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
            {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
            <Transition
              show={show}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="pointer-events-auto w-full max-w-sm overflow-hidden fixed bottom-4 right-4 z-50 rounded-lg bg-shell shadow-lg ring-1 ring-black ring-opacity-5 transition-opacity duration-500 opacity-100">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <XCircleIcon
                        className="h-6 w-6 text-alert"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-m font-medium text-foreground">{error.name}</p>
                      <p className="mt-1 text-sm text-foreground">{error.message}</p>
                    </div>
                    <div className="ml-4 flex flex-shrink-0">
                      <button
                        type="button"
                        className="inline-flex rounded-md bg-shell text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        )}
      </div>
    </>
  );
};

const ErrorFallback: FC<FallbackProps> = ({ error }) => {
  return <PopError error={error}></PopError>;
};
export default PopError;
